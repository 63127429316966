import styles from "./TextArea.module.css";

const TextArea = ({ line1 }) => {
  return (
    <div className={styles.textArea}>
      <div className={styles.typeYourMessage}>Type your message...</div>
      <img className={styles.textAreaChild} alt="" src={line1} />
      <img className={styles.textAreaItem} alt="" src="/line-2.svg" />
    </div>
  );
};

export default TextArea;
