import { useMemo } from "react";
import styles from "./BreakpointMobile1.module.css";

const BreakpointMobile1 = ({
  heading,
  relumeBlack,
  relumeBlack1,
  relumeBlack2,
  relumeBlack3,
  relumeBlack4,
  breakpointMobileFlexShrink,
}) => {
  const breakpointMobile1Style = useMemo(() => {
    return {
      flexShrink: breakpointMobileFlexShrink,
    };
  }, [breakpointMobileFlexShrink]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobile1Style}>
      <b className={styles.heading}>{heading}</b>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.column}>
            <img
              className={styles.webflowBlack}
              alt=""
              src="/webflow--black1.svg"
            />
            <img className={styles.relumeBlack} alt="" src={relumeBlack} />
            <img
              className={styles.webflowBlack}
              alt=""
              src="/webflow--black1.svg"
            />
            <img className={styles.relumeBlack} alt="" src={relumeBlack1} />
            <img
              className={styles.webflowBlack}
              alt=""
              src="/webflow--black1.svg"
            />
          </div>
          <div className={styles.column}>
            <img
              className={styles.webflowBlack}
              alt=""
              src="/webflow--black2.svg"
            />
            <img className={styles.relumeBlack} alt="" src={relumeBlack2} />
            <img
              className={styles.webflowBlack}
              alt=""
              src="/webflow--black2.svg"
            />
            <img className={styles.relumeBlack} alt="" src={relumeBlack3} />
            <img
              className={styles.webflowBlack}
              alt=""
              src="/webflow--black2.svg"
            />
          </div>
        </div>
        <img className={styles.relumeBlack} alt="" src={relumeBlack4} />
      </div>
    </div>
  );
};

export default BreakpointMobile1;
