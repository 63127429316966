import { useMemo } from "react";
import styles from "./StyleSecondarySmallFalse1.module.css";

const StyleSecondarySmallFalse1 = ({
  button,
  styleSecondarySmallFalseWidth,
}) => {
  const styleSecondarySmallFalse1Style = useMemo(() => {
    return {
      width: styleSecondarySmallFalseWidth,
    };
  }, [styleSecondarySmallFalseWidth]);

  return (
    <div
      className={styles.stylesecondarySmallfalse}
      style={styleSecondarySmallFalse1Style}
    >
      <div className={styles.button}>{button}</div>
    </div>
  );
};

export default StyleSecondarySmallFalse1;
