import { useMemo } from "react";
import styles from "./SelectedFalse.module.css";

const SelectedFalse = ({
  selectedFalseWidth,
  selectedFalseHeight,
  selectedFalsePadding,
  selectedFalseBoxSizing,
}) => {
  const selectedFalse1Style = useMemo(() => {
    return {
      width: selectedFalseWidth,
      height: selectedFalseHeight,
      padding: selectedFalsePadding,
      boxSizing: selectedFalseBoxSizing,
    };
  }, [
    selectedFalseWidth,
    selectedFalseHeight,
    selectedFalsePadding,
    selectedFalseBoxSizing,
  ]);

  return (
    <div className={styles.selectedfalse} style={selectedFalse1Style}>
      <div className={styles.checkbox} />
      <div className={styles.label}>Checkbox</div>
    </div>
  );
};

export default SelectedFalse;
