import { useMemo } from "react";
import StylePrimarySmallFalseDa from "./StylePrimarySmallFalseDa";
import styles from "./BreakpointMobile6.module.css";

const BreakpointMobile6 = ({
  subheading,
  heading,
  text,
  heading1,
  price,
  price1,
  text1,
  text2,
  text3,
  heading2,
  price2,
  price3,
  text4,
  text5,
  text6,
  text7,
  text8,
  breakpointMobileWidth,
  breakpointMobileFlexShrink,
  breakpointMobilePadding,
  contentGap,
  sectionTitleWidth,
  sectionTitleGap,
  contentGap1,
  headingFontSize,
  textAlignSelf,
  contentFlexDirection,
  contentAlignItems,
  columnJustifyContent,
  columnPadding,
  columnGap,
  columnFlex,
  contentGap2,
  priceFontSize,
  priceFontSize1,
  priceLineHeight,
  columnAlignSelf,
  columnPadding1,
  columnFlex1,
  priceGap,
  containerAlignSelf,
  priceFontSize2,
  priceFontSize3,
  priceLineHeight1,
}) => {
  const breakpointMobile6Style = useMemo(() => {
    return {
      width: breakpointMobileWidth,
      flexShrink: breakpointMobileFlexShrink,
      padding: breakpointMobilePadding,
    };
  }, [
    breakpointMobileWidth,
    breakpointMobileFlexShrink,
    breakpointMobilePadding,
  ]);

  const content8Style = useMemo(() => {
    return {
      gap: contentGap,
    };
  }, [contentGap]);

  const sectionTitle3Style = useMemo(() => {
    return {
      width: sectionTitleWidth,
      gap: sectionTitleGap,
    };
  }, [sectionTitleWidth, sectionTitleGap]);

  const content9Style = useMemo(() => {
    return {
      gap: contentGap1,
    };
  }, [contentGap1]);

  const heading3Style = useMemo(() => {
    return {
      fontSize: headingFontSize,
    };
  }, [headingFontSize]);

  const textStyle = useMemo(() => {
    return {
      alignSelf: textAlignSelf,
    };
  }, [textAlignSelf]);

  const content10Style = useMemo(() => {
    return {
      flexDirection: contentFlexDirection,
      alignItems: contentAlignItems,
    };
  }, [contentFlexDirection, contentAlignItems]);

  const columnStyle = useMemo(() => {
    return {
      justifyContent: columnJustifyContent,
      padding: columnPadding,
      gap: columnGap,
      flex: columnFlex,
    };
  }, [columnJustifyContent, columnPadding, columnGap, columnFlex]);

  const content11Style = useMemo(() => {
    return {
      gap: contentGap2,
    };
  }, [contentGap2]);

  const priceStyle = useMemo(() => {
    return {
      fontSize: priceFontSize,
    };
  }, [priceFontSize]);

  const price1Style = useMemo(() => {
    return {
      fontSize: priceFontSize1,
      lineHeight: priceLineHeight,
    };
  }, [priceFontSize1, priceLineHeight]);

  const column1Style = useMemo(() => {
    return {
      alignSelf: columnAlignSelf,
      padding: columnPadding1,
      flex: columnFlex1,
    };
  }, [columnAlignSelf, columnPadding1, columnFlex1]);

  const price2Style = useMemo(() => {
    return {
      gap: priceGap,
    };
  }, [priceGap]);

  const container2Style = useMemo(() => {
    return {
      alignSelf: containerAlignSelf,
    };
  }, [containerAlignSelf]);

  const price3Style = useMemo(() => {
    return {
      fontSize: priceFontSize2,
    };
  }, [priceFontSize2]);

  const price4Style = useMemo(() => {
    return {
      fontSize: priceFontSize3,
      lineHeight: priceLineHeight1,
    };
  }, [priceFontSize3, priceLineHeight1]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobile6Style}>
      <div className={styles.content} style={content8Style}>
        <div className={styles.sectionTitle} style={sectionTitle3Style}>
          <div className={styles.subheading}>{subheading}</div>
          <div className={styles.content1} style={content9Style}>
            <b className={styles.heading} style={heading3Style}>
              {heading}
            </b>
            <div className={styles.text} style={textStyle}>
              {text}
            </div>
          </div>
        </div>
        <div className={styles.tabs}>
          <div className={styles.button}>
            <div className={styles.monthly}>Monthly</div>
          </div>
          <div className={styles.button1}>
            <div className={styles.monthly}>Yearly</div>
          </div>
        </div>
      </div>
      <div className={styles.content2} style={content10Style}>
        <div className={styles.column} style={columnStyle}>
          <div className={styles.content3} style={content11Style}>
            <div className={styles.price}>
              <b className={styles.heading1}>{heading1}</b>
              <div className={styles.container}>
                <b className={styles.price1} style={priceStyle}>
                  {price}
                </b>
                <b className={styles.price2} style={price1Style}>
                  {price1}
                </b>
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.listItem}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <div className={styles.monthly}>{text1}</div>
              </div>
              <div className={styles.listItem}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <div className={styles.monthly}>{text2}</div>
              </div>
              <div className={styles.listItem}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <div className={styles.monthly}>{text3}</div>
              </div>
            </div>
          </div>
          <StylePrimarySmallFalseDa
            button="Button"
            stylePrimarySmallFalseDaAlignSelf="stretch"
            stylePrimarySmallFalseDaWidth="unset"
          />
        </div>
        <div className={styles.column} style={column1Style}>
          <div className={styles.content3} style={price2Style}>
            <div className={styles.price}>
              <b className={styles.heading1}>{heading2}</b>
              <div className={styles.container1} style={container2Style}>
                <b className={styles.price1} style={price3Style}>
                  {price2}
                </b>
                <b className={styles.price2} style={price4Style}>
                  {price3}
                </b>
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.listItem}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <div className={styles.monthly}>{text4}</div>
              </div>
              <div className={styles.listItem}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <div className={styles.monthly}>{text5}</div>
              </div>
              <div className={styles.listItem}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <div className={styles.monthly}>{text6}</div>
              </div>
              <div className={styles.listItem}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <div className={styles.monthly}>{text7}</div>
              </div>
              <div className={styles.listItem}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
                <div className={styles.monthly}>{text8}</div>
              </div>
            </div>
          </div>
          <StylePrimarySmallFalseDa
            button="Button"
            stylePrimarySmallFalseDaAlignSelf="stretch"
            stylePrimarySmallFalseDaWidth="unset"
          />
        </div>
      </div>
    </div>
  );
};

export default BreakpointMobile6;
