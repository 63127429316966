import SchoolifyContainer from "../components/SchoolifyContainer";
import SchoolifyContainer1 from "../components/SchoolifyContainer1";
import styles from "./Home.module.css";

const Home = () => {
  return (
    <div className={styles.home}>
      <SchoolifyContainer />
      <SchoolifyContainer1 />
    </div>
  );
};

export default Home;
