import { useMemo } from "react";
import styles from "./BreakpointMobile8.module.css";

const BreakpointMobile8 = ({
  heading,
  text,
  subheadingOne,
  text1,
  subheadingTwo,
  text2,
  placeholderImage,
  breakpointMobileWidth,
  breakpointMobileFlexShrink,
  breakpointMobilePadding,
  containerFlexDirection,
  containerAlignItems,
  containerGap,
  contentAlignSelf,
  contentGap,
  contentFlex,
  sectionTitleGap,
  headingFontSize,
  listFlexDirection,
  listItemAlignSelf,
  listItemGap,
  listItemFlex,
  listItemAlignSelf1,
  listItemGap1,
  listItemFlex1,
  placeholderImageIconAlignSelf,
  placeholderImageIconHeight,
  placeholderImageIconFlexShrink,
  placeholderImageIconFlex,
}) => {
  const breakpointMobile8Style = useMemo(() => {
    return {
      width: breakpointMobileWidth,
      flexShrink: breakpointMobileFlexShrink,
      padding: breakpointMobilePadding,
    };
  }, [
    breakpointMobileWidth,
    breakpointMobileFlexShrink,
    breakpointMobilePadding,
  ]);

  const container3Style = useMemo(() => {
    return {
      flexDirection: containerFlexDirection,
      alignItems: containerAlignItems,
      gap: containerGap,
    };
  }, [containerFlexDirection, containerAlignItems, containerGap]);

  const content18Style = useMemo(() => {
    return {
      alignSelf: contentAlignSelf,
      gap: contentGap,
      flex: contentFlex,
    };
  }, [contentAlignSelf, contentGap, contentFlex]);

  const sectionTitle4Style = useMemo(() => {
    return {
      gap: sectionTitleGap,
    };
  }, [sectionTitleGap]);

  const heading8Style = useMemo(() => {
    return {
      fontSize: headingFontSize,
    };
  }, [headingFontSize]);

  const listStyle = useMemo(() => {
    return {
      flexDirection: listFlexDirection,
    };
  }, [listFlexDirection]);

  const listItemStyle = useMemo(() => {
    return {
      alignSelf: listItemAlignSelf,
      gap: listItemGap,
      flex: listItemFlex,
    };
  }, [listItemAlignSelf, listItemGap, listItemFlex]);

  const listItem1Style = useMemo(() => {
    return {
      alignSelf: listItemAlignSelf1,
      gap: listItemGap1,
      flex: listItemFlex1,
    };
  }, [listItemAlignSelf1, listItemGap1, listItemFlex1]);

  const placeholderImageIcon4Style = useMemo(() => {
    return {
      alignSelf: placeholderImageIconAlignSelf,
      height: placeholderImageIconHeight,
      flexShrink: placeholderImageIconFlexShrink,
      flex: placeholderImageIconFlex,
    };
  }, [
    placeholderImageIconAlignSelf,
    placeholderImageIconHeight,
    placeholderImageIconFlexShrink,
    placeholderImageIconFlex,
  ]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobile8Style}>
      <div className={styles.container} style={container3Style}>
        <div className={styles.content} style={content18Style}>
          <div className={styles.sectionTitle} style={sectionTitle4Style}>
            <b className={styles.heading} style={heading8Style}>
              {heading}
            </b>
            <div className={styles.text}>{text}</div>
          </div>
          <div className={styles.list} style={listStyle}>
            <div className={styles.listItem} style={listItemStyle}>
              <img
                className={styles.iconRelume}
                alt=""
                src="/icon--relume1.svg"
              />
              <b className={styles.subheadingOne}>{subheadingOne}</b>
              <div className={styles.text}>{text1}</div>
            </div>
            <div className={styles.listItem} style={listItem1Style}>
              <img
                className={styles.iconRelume}
                alt=""
                src="/icon--relume1.svg"
              />
              <b className={styles.subheadingOne}>{subheadingTwo}</b>
              <div className={styles.text}>{text2}</div>
            </div>
          </div>
        </div>
        <img
          className={styles.placeholderImageIcon}
          alt=""
          src={placeholderImage}
          style={placeholderImageIcon4Style}
        />
      </div>
    </div>
  );
};

export default BreakpointMobile8;
