import { useMemo } from "react";
import styles from "./StylePrimarySmallTrueDar.module.css";

const StylePrimarySmallTrueDar = ({ stylePrimarySmallTrueDarAlignSelf }) => {
  const stylePrimarySmallTrueDarStyle = useMemo(() => {
    return {
      alignSelf: stylePrimarySmallTrueDarAlignSelf,
    };
  }, [stylePrimarySmallTrueDarAlignSelf]);

  return (
    <div
      className={styles.styleprimarySmalltrueDar}
      style={stylePrimarySmallTrueDarStyle}
    >
      <div className={styles.button}>Button</div>
    </div>
  );
};

export default StylePrimarySmallTrueDar;
