import { useMemo } from "react";
import TextInput from "./TextInput";
import StyleSecondarySmallFalse from "./StyleSecondarySmallFalse";
import styles from "./BreakpointMobile.module.css";

const BreakpointMobile = ({
  logo,
  columnOne,
  linkOne,
  linkTwo,
  linkThree,
  linkFour,
  linkFive,
  columnTwo,
  linkSix,
  linkSeven,
  linkEight,
  linkNine,
  linkTen,
  columnThree,
  linkEleven,
  linkTwelve,
  linkThirteen,
  linkFourteen,
  linkFifteen,
  joinOurNewsletterToStayUp,
  privacyPolicy,
  iconFacebook,
  iconInstagram,
  iconX,
  iconLinkedIn,
  iconYoutube,
  privacyPolicy1,
  breakpointMobileFlexShrink,
  privacyPolicyTextDecoration,
  privacyPolicyAlignSelf,
  privacyPolicyPosition,
  privacyPolicyBackgroundColor,
  privacyPolicyHeight,
  creditsAlignSelf,
  creditsFlexDirection,
  creditsGap,
  dividerAlignSelf,
  dividerBackgroundColor,
  dividerHeight,
  dividerWidth,
  dividerOverflow,
  dividerFlexShrink,
  rowAlignSelf,
  rowDisplay,
  rowFlexDirection,
  rowAlignItems,
  rowJustifyContent,
  rowPadding,
  rowBoxSizing,
  rowGap,
  rowPosition,
  rowWidth,
  rowHeight,
  rowOverflow,
  rowFlexShrink,
  socialLinksDisplay,
  socialLinksFlexDirection,
  socialLinksAlignItems,
  socialLinksJustifyContent,
  socialLinksGap,
  socialLinksPosition,
  socialLinksWidth,
  socialLinksHeight,
  socialLinksOverflow,
  socialLinksFlexShrink,
  iconXPosition,
  iconXWidth,
  iconXHeight,
  iconXOverflow,
  iconXFlexShrink,
  iconXDisplay,
  iconXFlexDirection,
  iconXAlignItems,
  iconXJustifyContent,
  iconXGap,
  iconLinkedInPosition,
  iconLinkedInWidth,
  iconLinkedInHeight,
  iconLinkedInOverflow,
  iconLinkedInFlexShrink,
  iconLinkedInDisplay,
  iconLinkedInFlexDirection,
  iconLinkedInAlignItems,
  iconLinkedInJustifyContent,
  iconLinkedInGap,
  iconYoutubeWidth,
  iconYoutubeHeight,
  iconYoutubeOverflow,
  iconYoutubeFlexShrink,
  iconYoutubeFontSize,
  iconYoutubeTextDecoration,
  iconYoutubeLineHeight,
  iconYoutubeFontFamily,
  iconYoutubeColor,
  iconYoutubeTextAlign,
  iconYoutubeDisplay,
  creditsDisplay,
  creditsFlexDirection1,
  creditsAlignItems,
  creditsJustifyContent,
  creditsGap1,
  creditsPosition,
  creditsFontSize,
  creditsTextDecoration,
  creditsLineHeight,
  creditsFontFamily,
  creditsColor,
  creditsTextAlign,
  footerLinksDisplay,
  footerLinksFlexDirection,
  footerLinksAlignItems,
  footerLinksJustifyContent,
  footerLinksGap,
  footerLinksPosition,
  footerLinksFontSize,
  footerLinksTextDecoration,
  footerLinksLineHeight,
  footerLinksFontFamily,
  footerLinksColor,
  footerLinksTextAlign,
  privacyPolicyTextDecoration1,
}) => {
  const breakpointMobileStyle = useMemo(() => {
    return {
      flexShrink: breakpointMobileFlexShrink,
    };
  }, [breakpointMobileFlexShrink]);

  const privacyPolicy2Style = useMemo(() => {
    return {
      textDecoration: privacyPolicyTextDecoration,
      alignSelf: privacyPolicyAlignSelf,
      position: privacyPolicyPosition,
      backgroundColor: privacyPolicyBackgroundColor,
      height: privacyPolicyHeight,
    };
  }, [
    privacyPolicyTextDecoration,
    privacyPolicyAlignSelf,
    privacyPolicyPosition,
    privacyPolicyBackgroundColor,
    privacyPolicyHeight,
  ]);

  const credits2Style = useMemo(() => {
    return {
      alignSelf: creditsAlignSelf,
      flexDirection: creditsFlexDirection,
      gap: creditsGap,
    };
  }, [creditsAlignSelf, creditsFlexDirection, creditsGap]);

  const divider1Style = useMemo(() => {
    return {
      alignSelf: dividerAlignSelf,
      backgroundColor: dividerBackgroundColor,
      height: dividerHeight,
      width: dividerWidth,
      overflow: dividerOverflow,
      flexShrink: dividerFlexShrink,
    };
  }, [
    dividerAlignSelf,
    dividerBackgroundColor,
    dividerHeight,
    dividerWidth,
    dividerOverflow,
    dividerFlexShrink,
  ]);

  const row1Style = useMemo(() => {
    return {
      alignSelf: rowAlignSelf,
      display: rowDisplay,
      flexDirection: rowFlexDirection,
      alignItems: rowAlignItems,
      justifyContent: rowJustifyContent,
      padding: rowPadding,
      boxSizing: rowBoxSizing,
      gap: rowGap,
      position: rowPosition,
      width: rowWidth,
      height: rowHeight,
      overflow: rowOverflow,
      flexShrink: rowFlexShrink,
    };
  }, [
    rowAlignSelf,
    rowDisplay,
    rowFlexDirection,
    rowAlignItems,
    rowJustifyContent,
    rowPadding,
    rowBoxSizing,
    rowGap,
    rowPosition,
    rowWidth,
    rowHeight,
    rowOverflow,
    rowFlexShrink,
  ]);

  const socialLinks1Style = useMemo(() => {
    return {
      display: socialLinksDisplay,
      flexDirection: socialLinksFlexDirection,
      alignItems: socialLinksAlignItems,
      justifyContent: socialLinksJustifyContent,
      gap: socialLinksGap,
      position: socialLinksPosition,
      width: socialLinksWidth,
      height: socialLinksHeight,
      overflow: socialLinksOverflow,
      flexShrink: socialLinksFlexShrink,
    };
  }, [
    socialLinksDisplay,
    socialLinksFlexDirection,
    socialLinksAlignItems,
    socialLinksJustifyContent,
    socialLinksGap,
    socialLinksPosition,
    socialLinksWidth,
    socialLinksHeight,
    socialLinksOverflow,
    socialLinksFlexShrink,
  ]);

  const iconXStyle = useMemo(() => {
    return {
      position: iconXPosition,
      width: iconXWidth,
      height: iconXHeight,
      overflow: iconXOverflow,
      flexShrink: iconXFlexShrink,
      display: iconXDisplay,
      flexDirection: iconXFlexDirection,
      alignItems: iconXAlignItems,
      justifyContent: iconXJustifyContent,
      gap: iconXGap,
    };
  }, [
    iconXPosition,
    iconXWidth,
    iconXHeight,
    iconXOverflow,
    iconXFlexShrink,
    iconXDisplay,
    iconXFlexDirection,
    iconXAlignItems,
    iconXJustifyContent,
    iconXGap,
  ]);

  const iconLinkedInStyle = useMemo(() => {
    return {
      position: iconLinkedInPosition,
      width: iconLinkedInWidth,
      height: iconLinkedInHeight,
      overflow: iconLinkedInOverflow,
      flexShrink: iconLinkedInFlexShrink,
      display: iconLinkedInDisplay,
      flexDirection: iconLinkedInFlexDirection,
      alignItems: iconLinkedInAlignItems,
      justifyContent: iconLinkedInJustifyContent,
      gap: iconLinkedInGap,
    };
  }, [
    iconLinkedInPosition,
    iconLinkedInWidth,
    iconLinkedInHeight,
    iconLinkedInOverflow,
    iconLinkedInFlexShrink,
    iconLinkedInDisplay,
    iconLinkedInFlexDirection,
    iconLinkedInAlignItems,
    iconLinkedInJustifyContent,
    iconLinkedInGap,
  ]);

  const iconYoutubeStyle = useMemo(() => {
    return {
      width: iconYoutubeWidth,
      height: iconYoutubeHeight,
      overflow: iconYoutubeOverflow,
      flexShrink: iconYoutubeFlexShrink,
      fontSize: iconYoutubeFontSize,
      textDecoration: iconYoutubeTextDecoration,
      lineHeight: iconYoutubeLineHeight,
      fontFamily: iconYoutubeFontFamily,
      color: iconYoutubeColor,
      textAlign: iconYoutubeTextAlign,
      display: iconYoutubeDisplay,
    };
  }, [
    iconYoutubeWidth,
    iconYoutubeHeight,
    iconYoutubeOverflow,
    iconYoutubeFlexShrink,
    iconYoutubeFontSize,
    iconYoutubeTextDecoration,
    iconYoutubeLineHeight,
    iconYoutubeFontFamily,
    iconYoutubeColor,
    iconYoutubeTextAlign,
    iconYoutubeDisplay,
  ]);

  const credits3Style = useMemo(() => {
    return {
      display: creditsDisplay,
      flexDirection: creditsFlexDirection1,
      alignItems: creditsAlignItems,
      justifyContent: creditsJustifyContent,
      gap: creditsGap1,
      position: creditsPosition,
      fontSize: creditsFontSize,
      textDecoration: creditsTextDecoration,
      lineHeight: creditsLineHeight,
      fontFamily: creditsFontFamily,
      color: creditsColor,
      textAlign: creditsTextAlign,
    };
  }, [
    creditsDisplay,
    creditsFlexDirection1,
    creditsAlignItems,
    creditsJustifyContent,
    creditsGap1,
    creditsPosition,
    creditsFontSize,
    creditsTextDecoration,
    creditsLineHeight,
    creditsFontFamily,
    creditsColor,
    creditsTextAlign,
  ]);

  const footerLinks1Style = useMemo(() => {
    return {
      display: footerLinksDisplay,
      flexDirection: footerLinksFlexDirection,
      alignItems: footerLinksAlignItems,
      justifyContent: footerLinksJustifyContent,
      gap: footerLinksGap,
      position: footerLinksPosition,
      fontSize: footerLinksFontSize,
      textDecoration: footerLinksTextDecoration,
      lineHeight: footerLinksLineHeight,
      fontFamily: footerLinksFontFamily,
      color: footerLinksColor,
      textAlign: footerLinksTextAlign,
    };
  }, [
    footerLinksDisplay,
    footerLinksFlexDirection,
    footerLinksAlignItems,
    footerLinksJustifyContent,
    footerLinksGap,
    footerLinksPosition,
    footerLinksFontSize,
    footerLinksTextDecoration,
    footerLinksLineHeight,
    footerLinksFontFamily,
    footerLinksColor,
    footerLinksTextAlign,
  ]);

  const privacyPolicy3Style = useMemo(() => {
    return {
      textDecoration: privacyPolicyTextDecoration1,
    };
  }, [privacyPolicyTextDecoration1]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobileStyle}>
      <div className={styles.content}>
        <div className={styles.links}>
          <div className={styles.column}>
            <img className={styles.logoIcon} alt="" src={logo} />
          </div>
          <div className={styles.column1}>
            <div className={styles.columnOne}>{columnOne}</div>
            <div className={styles.footerLinks}>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkOne}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkTwo}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkThree}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkFour}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkFive}</div>
              </div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.columnOne}>{columnTwo}</div>
            <div className={styles.footerLinks}>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkSix}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkSeven}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkEight}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkNine}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkTen}</div>
              </div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.columnOne}>{columnThree}</div>
            <div className={styles.footerLinks}>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkEleven}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkTwelve}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkThirteen}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkFourteen}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkFifteen}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.newslatter}>
          <div className={styles.subscribeParent}>
            <div className={styles.columnOne}>Subscribe</div>
            <div className={styles.joinOurNewsletter}>
              {joinOurNewsletterToStayUp}
            </div>
          </div>
          <div className={styles.actions}>
            <div className={styles.newslatter}>
              <TextInput />
              <StyleSecondarySmallFalse
                styleSecondarySmallFalseWidth="unset"
                styleSecondarySmallFalseAlignSelf="stretch"
              />
            </div>
            <div className={styles.bySubscribingYouContainer}>
              {`By subscribing you agree to with our `}
              <span
                className={styles.privacyPolicy}
                style={privacyPolicy2Style}
              >
                {privacyPolicy}
              </span>{" "}
              and provide consent to receive updates from our company.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.credits} style={credits2Style}>
        <div className={styles.divider} style={divider1Style} />
        <div className={styles.row} style={row1Style}>
          <div className={styles.socialLinks} style={socialLinks1Style}>
            <img className={styles.iconFacebook} alt="" src={iconFacebook} />
            <img className={styles.iconFacebook} alt="" src={iconInstagram} />
            <img
              className={styles.iconFacebook}
              alt=""
              src={iconX}
              style={iconXStyle}
            />
            <img
              className={styles.iconFacebook}
              alt=""
              src={iconLinkedIn}
              style={iconLinkedInStyle}
            />
            <img
              className={styles.iconFacebook}
              alt=""
              src={iconYoutube}
              style={iconYoutubeStyle}
            />
          </div>
          <div className={styles.credits1} style={credits3Style}>
            <div className={styles.footerLinks3} style={footerLinks1Style}>
              <div
                className={styles.termsOfService}
                style={privacyPolicy3Style}
              >
                {privacyPolicy1}
              </div>
              <div className={styles.termsOfService}>Terms of Service</div>
              <div className={styles.termsOfService}>Cookies Settings</div>
            </div>
            <div className={styles.relumeAllRights}>
              © 2023 Relume. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakpointMobile;
