import { useMemo } from "react";
import StyleLinkSmallFalseDark2 from "./StyleLinkSmallFalseDark2";
import styles from "./BreakpointMobile4.module.css";

const BreakpointMobile4 = ({
  subheading,
  heading,
  text,
  iconEnvelope,
  text1,
  link,
  iconPhone,
  text2,
  link1,
  iconMap,
  text3,
  placeholderImage,
  breakpointMobileWidth,
  breakpointMobileFlexShrink,
  breakpointMobilePadding,
  breakpointMobileGap,
  sectionTitleAlignSelf,
  sectionTitleGap,
  sectionTitleWidth,
  subheadingAlignSelf,
  subheadingTextAlign,
  contentGap,
  headingFontSize,
  contentFlexDirection,
  contentGap1,
  rowWidth,
  contentGap2,
  contentGap3,
  contentGap4,
  placeholderImageIconAlignSelf,
  placeholderImageIconMaxWidth,
  placeholderImageIconOverflow,
  placeholderImageIconHeight,
  placeholderImageIconFlexShrink,
  placeholderImageIconWidth,
}) => {
  const breakpointMobile4Style = useMemo(() => {
    return {
      width: breakpointMobileWidth,
      flexShrink: breakpointMobileFlexShrink,
      padding: breakpointMobilePadding,
      gap: breakpointMobileGap,
    };
  }, [
    breakpointMobileWidth,
    breakpointMobileFlexShrink,
    breakpointMobilePadding,
    breakpointMobileGap,
  ]);

  const sectionTitle2Style = useMemo(() => {
    return {
      alignSelf: sectionTitleAlignSelf,
      gap: sectionTitleGap,
      width: sectionTitleWidth,
    };
  }, [sectionTitleAlignSelf, sectionTitleGap, sectionTitleWidth]);

  const subheading1Style = useMemo(() => {
    return {
      alignSelf: subheadingAlignSelf,
      textAlign: subheadingTextAlign,
    };
  }, [subheadingAlignSelf, subheadingTextAlign]);

  const content2Style = useMemo(() => {
    return {
      gap: contentGap,
    };
  }, [contentGap]);

  const heading1Style = useMemo(() => {
    return {
      fontSize: headingFontSize,
    };
  }, [headingFontSize]);

  const content3Style = useMemo(() => {
    return {
      flexDirection: contentFlexDirection,
      gap: contentGap1,
    };
  }, [contentFlexDirection, contentGap1]);

  const row2Style = useMemo(() => {
    return {
      width: rowWidth,
    };
  }, [rowWidth]);

  const content4Style = useMemo(() => {
    return {
      gap: contentGap2,
    };
  }, [contentGap2]);

  const content5Style = useMemo(() => {
    return {
      gap: contentGap3,
    };
  }, [contentGap3]);

  const content6Style = useMemo(() => {
    return {
      gap: contentGap4,
    };
  }, [contentGap4]);

  const placeholderImageIconStyle = useMemo(() => {
    return {
      alignSelf: placeholderImageIconAlignSelf,
      maxWidth: placeholderImageIconMaxWidth,
      overflow: placeholderImageIconOverflow,
      height: placeholderImageIconHeight,
      flexShrink: placeholderImageIconFlexShrink,
      width: placeholderImageIconWidth,
    };
  }, [
    placeholderImageIconAlignSelf,
    placeholderImageIconMaxWidth,
    placeholderImageIconOverflow,
    placeholderImageIconHeight,
    placeholderImageIconFlexShrink,
    placeholderImageIconWidth,
  ]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobile4Style}>
      <div className={styles.sectionTitle} style={sectionTitle2Style}>
        <div className={styles.subheading} style={subheading1Style}>
          {subheading}
        </div>
        <div className={styles.content} style={content2Style}>
          <b className={styles.heading} style={heading1Style}>
            {heading}
          </b>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
      <div className={styles.content1} style={content3Style}>
        <div className={styles.row} style={row2Style}>
          <div className={styles.sectionTitle} style={content4Style}>
            <img className={styles.iconEnvelope} alt="" src={iconEnvelope} />
            <div className={styles.contactInfo}>
              <b className={styles.heading1}>Email</b>
              <div className={styles.text1}>{text1}</div>
              <div className={styles.link}>{link}</div>
            </div>
          </div>
          <div className={styles.sectionTitle} style={content5Style}>
            <img className={styles.iconEnvelope} alt="" src={iconPhone} />
            <div className={styles.contactInfo}>
              <b className={styles.heading1}>Phone</b>
              <div className={styles.text1}>{text2}</div>
              <div className={styles.link}>{link1}</div>
            </div>
          </div>
          <div className={styles.content4} style={content6Style}>
            <img className={styles.iconEnvelope} alt="" src={iconMap} />
            <div className={styles.contactInfo}>
              <b className={styles.heading3}>Office</b>
              <div className={styles.text}>{text3}</div>
              <div className={styles.actions}>
                <StyleLinkSmallFalseDark2
                  button="Button"
                  iconRelume="/icon--chevronright2.svg"
                  styleLinkSmallFalseDarkWidth="133px"
                />
              </div>
            </div>
          </div>
        </div>
        <img
          className={styles.placeholderImageIcon}
          alt=""
          src={placeholderImage}
          style={placeholderImageIconStyle}
        />
      </div>
    </div>
  );
};

export default BreakpointMobile4;
