import { useMemo } from "react";
import StyleSecondarySmallFalse1 from "./StyleSecondarySmallFalse1";
import styles from "./BreakpointMobile2.module.css";

const BreakpointMobile2 = ({
  fAQs,
  text,
  question,
  icon,
  question1,
  icon1,
  question2,
  icon2,
  question3,
  icon3,
  question4,
  icon4,
  breakpointMobileWidth,
  breakpointMobileFlexShrink,
  breakpointMobilePadding,
  containerFlexDirection,
  containerGap,
  sectionTitleAlignSelf,
  sectionTitleGap,
  sectionTitleWidth,
  contentGap,
  fAQsFontSize,
  accordionAlignSelf,
  accordionFlex,
  titlePadding,
  titlePadding1,
  titlePadding2,
  titlePadding3,
  titlePadding4,
}) => {
  const breakpointMobile2Style = useMemo(() => {
    return {
      width: breakpointMobileWidth,
      flexShrink: breakpointMobileFlexShrink,
      padding: breakpointMobilePadding,
    };
  }, [
    breakpointMobileWidth,
    breakpointMobileFlexShrink,
    breakpointMobilePadding,
  ]);

  const containerStyle = useMemo(() => {
    return {
      flexDirection: containerFlexDirection,
      gap: containerGap,
    };
  }, [containerFlexDirection, containerGap]);

  const sectionTitleStyle = useMemo(() => {
    return {
      alignSelf: sectionTitleAlignSelf,
      gap: sectionTitleGap,
      width: sectionTitleWidth,
    };
  }, [sectionTitleAlignSelf, sectionTitleGap, sectionTitleWidth]);

  const contentStyle = useMemo(() => {
    return {
      gap: contentGap,
    };
  }, [contentGap]);

  const fAQsStyle = useMemo(() => {
    return {
      fontSize: fAQsFontSize,
    };
  }, [fAQsFontSize]);

  const accordionStyle = useMemo(() => {
    return {
      alignSelf: accordionAlignSelf,
      flex: accordionFlex,
    };
  }, [accordionAlignSelf, accordionFlex]);

  const titleStyle = useMemo(() => {
    return {
      padding: titlePadding,
    };
  }, [titlePadding]);

  const title1Style = useMemo(() => {
    return {
      padding: titlePadding1,
    };
  }, [titlePadding1]);

  const title2Style = useMemo(() => {
    return {
      padding: titlePadding2,
    };
  }, [titlePadding2]);

  const title3Style = useMemo(() => {
    return {
      padding: titlePadding3,
    };
  }, [titlePadding3]);

  const title4Style = useMemo(() => {
    return {
      padding: titlePadding4,
    };
  }, [titlePadding4]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobile2Style}>
      <div className={styles.container} style={containerStyle}>
        <div className={styles.sectionTitle} style={sectionTitleStyle}>
          <div className={styles.content} style={contentStyle}>
            <b className={styles.faqs} style={fAQsStyle}>
              {fAQs}
            </b>
            <div className={styles.text}>{text}</div>
          </div>
          <StyleSecondarySmallFalse1
            button="Button"
            styleSecondarySmallFalseWidth="106px"
          />
        </div>
        <div className={styles.accordion} style={accordionStyle}>
          <div className={styles.accordionItem}>
            <div className={styles.divider} />
            <div className={styles.title} style={titleStyle}>
              <b className={styles.question}>{question}</b>
              <img className={styles.icon} alt="" src={icon} />
            </div>
          </div>
          <div className={styles.accordionItem}>
            <div className={styles.divider} />
            <div className={styles.title} style={title1Style}>
              <b className={styles.question}>{question1}</b>
              <img className={styles.icon} alt="" src={icon1} />
            </div>
          </div>
          <div className={styles.accordionItem}>
            <div className={styles.divider} />
            <div className={styles.title} style={title2Style}>
              <b className={styles.question}>{question2}</b>
              <img className={styles.icon} alt="" src={icon2} />
            </div>
          </div>
          <div className={styles.accordionItem}>
            <div className={styles.divider} />
            <div className={styles.title} style={title3Style}>
              <b className={styles.question}>{question3}</b>
              <img className={styles.icon} alt="" src={icon3} />
            </div>
          </div>
          <div className={styles.accordionItem}>
            <div className={styles.divider} />
            <div className={styles.title} style={title4Style}>
              <b className={styles.question}>{question4}</b>
              <img className={styles.icon} alt="" src={icon4} />
            </div>
          </div>
          <div className={styles.divider} />
        </div>
      </div>
    </div>
  );
};

export default BreakpointMobile2;
