import styles from "./StyleLinkSmallFalseDark1.module.css";

const StyleLinkSmallFalseDark1 = ({ button, iconRelume }) => {
  return (
    <div className={styles.stylelinkSmallfalseDark}>
      <div className={styles.button}>{button}</div>
      <img className={styles.iconRelume} alt="" src={iconRelume} />
    </div>
  );
};

export default StyleLinkSmallFalseDark1;
