import StyleSecondarySmallTrueD1 from "./StyleSecondarySmallTrueD1";
import StylePrimarySmallTrueDar1 from "./StylePrimarySmallTrueDar1";
import styles from "./LinkCard.module.css";

const LinkCard = ({
  linkText,
  linkText2,
  linkDescription,
  linkIcon,
  linkButtonText,
  imageDimensions,
}) => {
  return (
    <div className={styles.content}>
      <div className={styles.content1}>
        <img className={styles.logoIcon} alt="" src={linkText} />
        <div className={styles.column}>
          <div className={styles.linkOne}>{linkText2}</div>
          <div className={styles.linkOne}>{linkDescription}</div>
          <div className={styles.linkOne}>{linkIcon}</div>
          <div className={styles.navLinkDropdown}>
            <div className={styles.linkOne}>{linkButtonText}</div>
            <img
              className={styles.chevronDownIcon}
              alt=""
              src={imageDimensions}
            />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <StyleSecondarySmallTrueD1 />
        <StylePrimarySmallTrueDar1 />
      </div>
    </div>
  );
};

export default LinkCard;
