import { useMemo } from "react";
import StylePrimarySmallFalseDa from "./StylePrimarySmallFalseDa";
import StyleSecondarySmallFalse1 from "./StyleSecondarySmallFalse1";
import styles from "./BreakpointMobile5.module.css";

const BreakpointMobile5 = ({
  heading,
  text,
  button,
  button1,
  breakpointMobileWidth,
  breakpointMobileFlexShrink,
  breakpointMobilePadding,
  containerFlexDirection,
  containerGap,
  headingAlignSelf,
  headingFontSize,
  headingFlex,
  contentAlignSelf,
  contentGap,
  contentFlex,
}) => {
  const breakpointMobile5Style = useMemo(() => {
    return {
      width: breakpointMobileWidth,
      flexShrink: breakpointMobileFlexShrink,
      padding: breakpointMobilePadding,
    };
  }, [
    breakpointMobileWidth,
    breakpointMobileFlexShrink,
    breakpointMobilePadding,
  ]);

  const container1Style = useMemo(() => {
    return {
      flexDirection: containerFlexDirection,
      gap: containerGap,
    };
  }, [containerFlexDirection, containerGap]);

  const heading2Style = useMemo(() => {
    return {
      alignSelf: headingAlignSelf,
      fontSize: headingFontSize,
      flex: headingFlex,
    };
  }, [headingAlignSelf, headingFontSize, headingFlex]);

  const content7Style = useMemo(() => {
    return {
      alignSelf: contentAlignSelf,
      gap: contentGap,
      flex: contentFlex,
    };
  }, [contentAlignSelf, contentGap, contentFlex]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobile5Style}>
      <div className={styles.container} style={container1Style}>
        <b className={styles.heading} style={heading2Style}>
          {heading}
        </b>
        <div className={styles.content} style={content7Style}>
          <div className={styles.text}>{text}</div>
          <div className={styles.actions}>
            <StylePrimarySmallFalseDa
              button="Button"
              stylePrimarySmallFalseDaAlignSelf="unset"
              stylePrimarySmallFalseDaWidth="unset"
            />
            <StyleSecondarySmallFalse1
              button="Button"
              styleSecondarySmallFalseWidth="unset"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakpointMobile5;
