import { useMemo } from "react";
import styles from "./StyleSecondarySmallFalse.module.css";

const StyleSecondarySmallFalse = ({
  styleSecondarySmallFalseWidth,
  styleSecondarySmallFalseAlignSelf,
}) => {
  const styleSecondarySmallFalseStyle = useMemo(() => {
    return {
      width: styleSecondarySmallFalseWidth,
      alignSelf: styleSecondarySmallFalseAlignSelf,
    };
  }, [styleSecondarySmallFalseWidth, styleSecondarySmallFalseAlignSelf]);

  return (
    <div
      className={styles.stylesecondarySmallfalse}
      style={styleSecondarySmallFalseStyle}
    >
      <div className={styles.button}>Button</div>
    </div>
  );
};

export default StyleSecondarySmallFalse;
