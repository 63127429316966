import { useMemo } from "react";
import styles from "./StyleSecondarySmallTrueD.module.css";

const StyleSecondarySmallTrueD = ({ styleSecondarySmallTrueDAlignSelf }) => {
  const styleSecondarySmallTrueDStyle = useMemo(() => {
    return {
      alignSelf: styleSecondarySmallTrueDAlignSelf,
    };
  }, [styleSecondarySmallTrueDAlignSelf]);

  return (
    <div
      className={styles.stylesecondarySmalltrueD}
      style={styleSecondarySmallTrueDStyle}
    >
      <div className={styles.button}>Button</div>
    </div>
  );
};

export default StyleSecondarySmallTrueD;
