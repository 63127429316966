import styles from "./TextInput3.module.css";

const TextInput3 = () => {
  return (
    <div className={styles.textInput}>
      <div className={styles.placeholder}>Placeholder</div>
    </div>
  );
};

export default TextInput3;
