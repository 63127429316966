import { useMemo } from "react";
import TextInput2 from "./TextInput2";
import TextInput1 from "./TextInput1";
import TextArea from "./TextArea";
import SelectedFalse from "./SelectedFalse";
import StylePrimarySmallFalseDa from "./StylePrimarySmallFalseDa";
import styles from "./BreakpointMobile3.module.css";

const BreakpointMobile3 = ({
  subheading,
  heading,
  text,
  breakpointMobileWidth,
  breakpointMobileFlexShrink,
  breakpointMobilePadding,
  breakpointMobileGap,
  sectionTitleAlignSelf,
  sectionTitleGap,
  sectionTitleWidth,
  subheadingAlignSelf,
  subheadingTextAlign,
  contentGap,
  headingFontSize,
  formAlignSelf,
  formWidth,
}) => {
  const breakpointMobile3Style = useMemo(() => {
    return {
      width: breakpointMobileWidth,
      flexShrink: breakpointMobileFlexShrink,
      padding: breakpointMobilePadding,
      gap: breakpointMobileGap,
    };
  }, [
    breakpointMobileWidth,
    breakpointMobileFlexShrink,
    breakpointMobilePadding,
    breakpointMobileGap,
  ]);

  const sectionTitle1Style = useMemo(() => {
    return {
      alignSelf: sectionTitleAlignSelf,
      gap: sectionTitleGap,
      width: sectionTitleWidth,
    };
  }, [sectionTitleAlignSelf, sectionTitleGap, sectionTitleWidth]);

  const subheadingStyle = useMemo(() => {
    return {
      alignSelf: subheadingAlignSelf,
      textAlign: subheadingTextAlign,
    };
  }, [subheadingAlignSelf, subheadingTextAlign]);

  const content1Style = useMemo(() => {
    return {
      gap: contentGap,
    };
  }, [contentGap]);

  const headingStyle = useMemo(() => {
    return {
      fontSize: headingFontSize,
    };
  }, [headingFontSize]);

  const formStyle = useMemo(() => {
    return {
      alignSelf: formAlignSelf,
      width: formWidth,
    };
  }, [formAlignSelf, formWidth]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobile3Style}>
      <div className={styles.sectionTitle} style={sectionTitle1Style}>
        <div className={styles.subheading} style={subheadingStyle}>
          {subheading}
        </div>
        <div className={styles.content} style={content1Style}>
          <b className={styles.heading} style={headingStyle}>
            {heading}
          </b>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
      <div className={styles.form} style={formStyle}>
        <div className={styles.input}>
          <div className={styles.name}>Name</div>
          <TextInput2 />
        </div>
        <div className={styles.input}>
          <div className={styles.name}>Email</div>
          <TextInput1 />
        </div>
        <div className={styles.input}>
          <div className={styles.name}>Message</div>
          <TextArea line1="/line-1.svg" />
        </div>
        <SelectedFalse
          selectedFalseWidth="146px"
          selectedFalseHeight="37px"
          selectedFalsePadding="0px 0px var(--padding-base)"
          selectedFalseBoxSizing="border-box"
        />
        <StylePrimarySmallFalseDa
          button="Button"
          stylePrimarySmallFalseDaAlignSelf="unset"
          stylePrimarySmallFalseDaWidth="101px"
        />
      </div>
    </div>
  );
};

export default BreakpointMobile3;
