import { useMemo } from "react";
import styles from "./StyleLinkSmallFalseDark2.module.css";

const StyleLinkSmallFalseDark2 = ({
  button,
  iconRelume,
  styleLinkSmallFalseDarkWidth,
}) => {
  const styleLinkSmallFalseDarkStyle = useMemo(() => {
    return {
      width: styleLinkSmallFalseDarkWidth,
    };
  }, [styleLinkSmallFalseDarkWidth]);

  return (
    <div
      className={styles.stylelinkSmallfalseDark}
      style={styleLinkSmallFalseDarkStyle}
    >
      <div className={styles.button}>{button}</div>
      <img className={styles.iconRelume} alt="" src={iconRelume} />
    </div>
  );
};

export default StyleLinkSmallFalseDark2;
