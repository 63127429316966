import BreakpointDesktop10 from "./BreakpointDesktop10";
import BreakpointMobile9 from "./BreakpointMobile9";
import BreakpointMobile8 from "./BreakpointMobile8";
import BreakpointMobile7 from "./BreakpointMobile7";
import BreakpointMobile6 from "./BreakpointMobile6";
import BreakpointMobile5 from "./BreakpointMobile5";
import BreakpointMobile4 from "./BreakpointMobile4";
import BreakpointMobile3 from "./BreakpointMobile3";
import BreakpointMobile2 from "./BreakpointMobile2";
import BreakpointDesktop1 from "./BreakpointDesktop1";
import BreakpointDesktop from "./BreakpointDesktop";
import styles from "./SchoolifyContainer.module.css";

const SchoolifyContainer = () => {
  return (
    <div className={styles.home}>
      <BreakpointDesktop10
        logo="/logo.svg"
        linkOne="About Us"
        linkTwo="Services"
        linkThree="Contact"
        linkFour="More"
        chevronDown="/chevron-down.svg"
        pageGroupOne="Popular Pages"
        pageOne="Home"
        loremIpsumDolorSitAmetCon="Learn More"
        pageTwo="Features"
        loremIpsumDolorSitAmetCon1="Discover More"
        pageThree="Benefits"
        loremIpsumDolorSitAmetCon2="Explore More"
        pageFour="Demo"
        loremIpsumDolorSitAmetCon3="Try It Now"
        pageGroupTwo="Resources"
        pageFive="Blog"
        loremIpsumDolorSitAmetCon4="Read Our Articles"
        pageSix="FAQ"
        loremIpsumDolorSitAmetCon5="Get Answers Here"
        pageSeven="Support"
        loremIpsumDolorSitAmetCon6="Contact Our Team"
        pageEight="Pricing"
        loremIpsumDolorSitAmetCon7="View Our Plans"
        pageGroupThree="Latest Articles"
        pageNine="How to"
        loremIpsumDolorSitAmetCon8="Learn Something New"
        pageTen="Tips"
        loremIpsumDolorSitAmetCon9="Improve Your Skills"
        pageEleven="Trends"
        loremIpsumDolorSitAmetCon10="Stay Up to Date"
        pageTwelve="Case Studies"
        loremIpsumDolorSitAmetCon11="See Our Successes"
        articleTitle="Title Here"
        readMore="Read More"
        seeAllArticles="View All Articles"
        chevronDown1="/chevron-down1.svg"
        showMegaMenu={false}
        breakpointDesktopFlexShrink="unset"
      />
      <BreakpointMobile9
        placeholderImage="/placeholder-image1@2x.png"
        mediumLengthHeroHeadlineG="Streamline Your School Operations with Schoolify"
        loremIpsumDolorSitAmetCon="Manage your school efficiently with our all-in-one school management system. Simplify administrative tasks, improve communication, and enhance student learning."
        button="Learn More"
        button1="Sign Up"
        breakpointMobileWidth="1440px"
        breakpointMobileHeight="1024px"
        contentFlexDirection="row"
        contentPadding="var(--padding-61xl) var(--padding-45xl)"
        contentGap="80px"
        columnAlignSelf="unset"
        columnFlex="1"
        mediumLengthHeroFontSize="56px"
        columnAlignSelf1="unset"
        columnGap="24px"
        columnFlex1="1"
      />
      <BreakpointMobile8
        heading="Simplify School Management with Schoolify's Powerful Features"
        text="Discover how Schoolify can streamline student management, track attendance, and generate report cards with ease."
        subheadingOne="Student Management"
        text1="Effortlessly organize student information, track progress, and communicate with parents."
        subheadingTwo="Attendance Tracking"
        text2="Keep accurate records of student attendance and easily generate attendance reports."
        placeholderImage="/placeholder-image2@2x.png"
        breakpointMobileWidth="1440px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-93xl) var(--padding-45xl)"
        containerFlexDirection="row"
        containerAlignItems="center"
        containerGap="80px"
        contentAlignSelf="unset"
        contentGap="32px"
        contentFlex="1"
        sectionTitleGap="24px"
        headingFontSize="40px"
        listFlexDirection="row"
        listItemAlignSelf="unset"
        listItemGap="16px"
        listItemFlex="1"
        listItemAlignSelf1="unset"
        listItemGap1="16px"
        listItemFlex1="1"
        placeholderImageIconAlignSelf="unset"
        placeholderImageIconHeight="640px"
        placeholderImageIconFlexShrink="unset"
        placeholderImageIconFlex="1"
      />
      <BreakpointMobile7
        heading="Effortlessly manage student enrollment, course scheduling, and fee management"
        placeholderImage="/placeholder-image3@2x.png"
        heading1="Streamline your school operations with Schoolify's powerful features"
        text="Schoolify offers a comprehensive solution for managing student enrollment, course scheduling, and fee management. With our intuitive platform, you can easily handle all aspects of your school's administrative tasks."
        button="Learn More"
        placeholderImage1="/placeholder-image4@2x.png"
        heading2="Enhance communication and collaboration with Schoolify's integrated messaging system"
        text1="Our messaging system allows seamless communication between students, parents, and teachers, fostering a collaborative learning environment."
        button1="Learn More"
        iconChevronRight="/icon--chevronright.svg"
        placeholderImage2="/placeholder-image5@2x.png"
        heading3="Efficiently manage school resources and facilities with Schoolify's advanced tools"
        text2="From managing classrooms and equipment to scheduling facilities, Schoolify provides the tools you need to optimize resource allocation."
        button2="Learn More"
        iconChevronRight1="/icon--chevronright1.svg"
        breakpointMobileWidth="1440px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-93xl) var(--padding-45xl)"
        breakpointMobileGap="80px"
        headingAlignSelf="unset"
        headingFontSize="40px"
        headingWidth="768px"
        rowFlexDirection="row"
        rowAlignItems="flex-start"
        rowJustifyContent="center"
        columnAlignSelf="unset"
        columnGap="32px"
        columnFlex="1"
        placeholderImageIconHeight="240px"
        contentGap="24px"
        contentGap1="16px"
        headingFontSize1="24px"
        columnAlignSelf1="unset"
        columnGap1="32px"
        columnFlex1="1"
        placeholderImageIconHeight1="240px"
        contentGap2="24px"
        contentGap3="16px"
        headingFontSize2="24px"
        columnAlignSelf2="unset"
        columnGap2="32px"
        columnFlex2="1"
        placeholderImageIconHeight2="240px"
        contentGap4="24px"
        contentGap5="16px"
        headingFontSize3="24px"
      />
      <BreakpointMobile8
        heading="Unlock the Potential of Your School with Schoolify's Powerful Features"
        text="Schoolify offers improved efficiency, better communication, and data-driven decision-making for your school. Experience the benefits today!"
        subheadingOne="Improved Efficiency"
        text1="Streamline administrative tasks, automate processes, and save time with Schoolify's intuitive interface."
        subheadingTwo="Better Communication"
        text2="Enhance collaboration, facilitate parent-teacher communication, and keep everyone informed in real-time."
        placeholderImage="/placeholder-image6@2x.png"
        breakpointMobileWidth="1440px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-93xl) var(--padding-45xl)"
        containerFlexDirection="row"
        containerAlignItems="center"
        containerGap="80px"
        contentAlignSelf="unset"
        contentGap="32px"
        contentFlex="1"
        sectionTitleGap="24px"
        headingFontSize="40px"
        listFlexDirection="row"
        listItemAlignSelf="unset"
        listItemGap="16px"
        listItemFlex="1"
        listItemAlignSelf1="unset"
        listItemGap1="16px"
        listItemFlex1="1"
        placeholderImageIconAlignSelf="unset"
        placeholderImageIconHeight="640px"
        placeholderImageIconFlexShrink="unset"
        placeholderImageIconFlex="1"
      />
      <BreakpointMobile6
        subheading="Simplify"
        heading="Pricing Options"
        text="Choose the plan that suits your needs"
        heading1="Basic Plan"
        price="$19/mo"
        text1="User-friendly interface"
        text2="Attendance tracking"
        text3="Gradebook management"
        heading2="Business Plan"
        price2="$29/mo"
        text4="Advanced reporting features"
        text5="Parent communication tools"
        text6="Customizable grade scales"
        text7="Online assignment submission"
        text8="Integrated messaging system"
        breakpointMobileWidth="1440px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-93xl) var(--padding-45xl)"
        contentGap="48px"
        sectionTitleWidth="768px"
        sectionTitleGap="16px"
        contentGap1="24px"
        headingFontSize="48px"
        textAlignSelf="unset"
        contentFlexDirection="row"
        contentAlignItems="flex-start"
        columnJustifyContent="space-between"
        columnPadding="32px 24px"
        columnGap="unset"
        columnFlex="1"
        contentGap2="32px"
        priceFontSize="56px"
        priceFontSize1="32px"
        priceLineHeight="130%"
        columnAlignSelf="unset"
        columnPadding1="32px 24px"
        columnFlex1="1"
        priceGap="32px"
        containerAlignSelf="stretch"
        priceFontSize2="56px"
        priceFontSize3="32px"
        priceLineHeight1="130%"
      />
      <BreakpointMobile5
        heading="Transforming Education with Schoolify"
        text="Discover how Schoolify simplifies school management and enhances student learning. Sign up for a demo today!"
        button="Sign Up"
        button1="Request Demo"
        breakpointMobileWidth="1440px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-93xl) var(--padding-45xl)"
        containerFlexDirection="row"
        containerGap="80px"
        headingAlignSelf="unset"
        headingFontSize="48px"
        headingFlex="1"
        contentAlignSelf="unset"
        contentGap="24px"
        contentFlex="1"
      />
      <BreakpointMobile4
        subheading="Simplify"
        heading="Contact Schoolify"
        text="Have questions or need support? Get in touch with us."
        iconEnvelope="/icon--envelope.svg"
        text1="Send us an email"
        link="hello@schoolify.com"
        iconPhone="/icon--phone.svg"
        text2="Call us for assistance"
        link1="+1 (555) 123-4567"
        iconMap="/icon--map.svg"
        text3="123 Schoolify St, Sydney NSW 2000 AU"
        placeholderImage="/placeholder-image7@2x.png"
        breakpointMobileWidth="1440px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-93xl) var(--padding-45xl)"
        breakpointMobileGap="80px"
        sectionTitleAlignSelf="unset"
        sectionTitleGap="16px"
        sectionTitleWidth="768px"
        subheadingAlignSelf="unset"
        subheadingTextAlign="center"
        contentGap="24px"
        headingFontSize="48px"
        contentFlexDirection="row"
        contentGap1="80px"
        rowWidth="400px"
        contentGap2="16px"
        contentGap3="16px"
        contentGap4="16px"
        placeholderImageIconAlignSelf="unset"
        placeholderImageIconMaxWidth="unset"
        placeholderImageIconOverflow="unset"
        placeholderImageIconHeight="516px"
        placeholderImageIconFlexShrink="unset"
        placeholderImageIconWidth="832px"
      />
      <BreakpointMobile3
        subheading="Simplify"
        heading="Get in Touch"
        text="Have a question or want to request a demo?"
        breakpointMobileWidth="1440px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-93xl) var(--padding-45xl)"
        breakpointMobileGap="48px"
        sectionTitleAlignSelf="unset"
        sectionTitleGap="16px"
        sectionTitleWidth="768px"
        subheadingAlignSelf="unset"
        subheadingTextAlign="center"
        contentGap="24px"
        headingFontSize="48px"
        formAlignSelf="unset"
        formWidth="560px"
      />
      <BreakpointMobile2
        fAQs="Frequently Asked Questions"
        text="Find answers to common questions about Schoolify, its features, and implementation process."
        question="What is Schoolify?"
        icon="/icon.svg"
        question1="How does it work?"
        icon1="/icon.svg"
        question2="Is Schoolify customizable?"
        icon2="/icon.svg"
        question3="How can I implement Schoolify?"
        icon3="/icon.svg"
        question4="What support is available?"
        icon4="/icon.svg"
        breakpointMobileWidth="1440px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-93xl) var(--padding-45xl)"
        containerFlexDirection="row"
        containerGap="80px"
        sectionTitleAlignSelf="unset"
        sectionTitleGap="32px"
        sectionTitleWidth="500px"
        contentGap="24px"
        fAQsFontSize="48px"
        accordionAlignSelf="unset"
        accordionFlex="1"
        titlePadding="var(--padding-xl) 0px"
        titlePadding1="var(--padding-xl) 0px"
        titlePadding2="var(--padding-xl) 0px"
        titlePadding3="var(--padding-xl) 0px"
        titlePadding4="var(--padding-xl) 0px"
      />
      <BreakpointDesktop1
        heading="Trusted by leading schools and institutions worldwide"
        relumeBlack="/relume--black.svg"
        relumeBlack1="/relume--black.svg"
        relumeBlack2="/relume--black.svg"
        relumeBlack3="/relume--black.svg"
        relumeBlack4="/relume--black.svg"
        breakpointDesktopFlexShrink="unset"
      />
      <BreakpointDesktop
        logo="/logo1.svg"
        columnOne="About Us"
        linkOne="Features"
        linkTwo="Pricing"
        linkThree="Contact Us"
        linkFour="Support"
        linkFive="FAQ"
        columnTwo="Terms"
        linkSix="Privacy"
        linkSeven="Blog"
        linkEight="Help Center"
        linkNine="Resources"
        linkTen="Partners"
        columnThree="Career"
        linkEleven="Press"
        linkTwelve="Events"
        linkThirteen="Terms of Use"
        linkFourteen="Privacy Policy"
        linkFifteen="Cookie Policy"
        joinOurNewsletterToStayUp="Join our mailing list to receive updates and exclusive offers."
        relumeAllRightsReserved="Terms of Service"
        iconFacebook="/icon--linkedin.svg"
        iconInstagram="/icon--youtube.svg"
        breakpointDesktopFlexShrink="unset"
        privacyPolicyTextDecoration="unset"
        privacyPolicyAlignSelf="stretch"
        privacyPolicyPosition="relative"
        privacyPolicyBackgroundColor="#000"
        privacyPolicyHeight="1px"
        creditsAlignSelf="unset"
        creditsFlexDirection="row"
        creditsAlignItems="center"
        creditsGap="24px"
        dividerAlignSelf="unset"
        dividerBackgroundColor="unset"
        dividerHeight="unset"
        dividerFontSize="14px"
        dividerLineHeight="150%"
        dividerFontFamily="Roboto"
        dividerColor="#000"
        dividerTextAlign="left"
        dividerDisplay="inline-block"
        rowAlignSelf="unset"
        rowJustifyContent="flex-start"
        rowGap="24px"
        creditsDisplay="inline-block"
        creditsFlexDirection1="unset"
        creditsAlignItems1="unset"
        creditsJustifyContent="unset"
        creditsGap1="unset"
        creditsPosition="relative"
        creditsFontSize="14px"
        creditsTextDecoration="underline"
        creditsLineHeight="150%"
        creditsFontFamily="Roboto"
        creditsColor="#000"
        creditsTextAlign="left"
        relumeAllRightsTextDecoration="underline"
        footerLinksDisplay="inline-block"
        footerLinksFlexDirection="unset"
        footerLinksAlignItems="unset"
        footerLinksJustifyContent="unset"
        footerLinksGap="unset"
        footerLinksPosition="relative"
        footerLinksFontSize="14px"
        footerLinksTextDecoration="underline"
        footerLinksLineHeight="150%"
        footerLinksFontFamily="Roboto"
        footerLinksColor="#000"
        footerLinksTextAlign="left"
        privacyPolicyPosition1="unset"
        privacyPolicyFontSize="unset"
        privacyPolicyTextDecoration1="unset"
        privacyPolicyLineHeight="unset"
        privacyPolicyFontFamily="unset"
        privacyPolicyColor="unset"
        privacyPolicyTextAlign="unset"
        privacyPolicyDisplay="flex"
        privacyPolicyFlexDirection="row"
        privacyPolicyAlignItems="flex-start"
        privacyPolicyJustifyContent="flex-start"
        privacyPolicyGap="12px"
        termsOfServiceFontSize="unset"
        termsOfServiceTextDecoration="unset"
        termsOfServiceLineHeight="unset"
        termsOfServiceFontFamily="unset"
        termsOfServiceColor="unset"
        termsOfServiceTextAlign="unset"
        termsOfServiceDisplay="unset"
        termsOfServiceWidth="24px"
        termsOfServiceHeight="24px"
        termsOfServiceOverflow="hidden"
        termsOfServiceFlexShrink="0"
        cookiesSettingsFontSize="unset"
        cookiesSettingsTextDecoration="unset"
        cookiesSettingsLineHeight="unset"
        cookiesSettingsFontFamily="unset"
        cookiesSettingsColor="unset"
        cookiesSettingsTextAlign="unset"
        cookiesSettingsDisplay="unset"
        cookiesSettingsWidth="24px"
        cookiesSettingsHeight="24px"
        cookiesSettingsOverflow="hidden"
        cookiesSettingsFlexShrink="0"
        socialLinksDisplay="unset"
        socialLinksFlexDirection="unset"
        socialLinksAlignItems="unset"
        socialLinksJustifyContent="unset"
        socialLinksGap="unset"
        socialLinksPosition="relative"
        socialLinksWidth="24px"
        socialLinksHeight="24px"
        socialLinksOverflow="hidden"
        socialLinksFlexShrink="0"
      />
    </div>
  );
};

export default SchoolifyContainer;
