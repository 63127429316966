import { useMemo } from "react";
import styles from "./BreakpointDesktop1.module.css";

const BreakpointDesktop1 = ({
  heading,
  relumeBlack,
  relumeBlack1,
  relumeBlack2,
  relumeBlack3,
  relumeBlack4,
  breakpointDesktopFlexShrink,
}) => {
  const breakpointDesktop1Style = useMemo(() => {
    return {
      flexShrink: breakpointDesktopFlexShrink,
    };
  }, [breakpointDesktopFlexShrink]);

  return (
    <div className={styles.breakpointDesktop} style={breakpointDesktop1Style}>
      <b className={styles.heading}>{heading}</b>
      <div className={styles.content}>
        <div className={styles.row}>
          <img
            className={styles.webflowBlack}
            alt=""
            src="/webflow--black.svg"
          />
          <img className={styles.relumeBlack} alt="" src={relumeBlack} />
          <img
            className={styles.webflowBlack}
            alt=""
            src="/webflow--black.svg"
          />
          <img className={styles.relumeBlack} alt="" src={relumeBlack1} />
          <img
            className={styles.webflowBlack}
            alt=""
            src="/webflow--black.svg"
          />
          <img className={styles.relumeBlack} alt="" src={relumeBlack2} />
        </div>
        <div className={styles.row}>
          <img
            className={styles.webflowBlack}
            alt=""
            src="/webflow--black.svg"
          />
          <img className={styles.relumeBlack} alt="" src={relumeBlack3} />
          <img
            className={styles.webflowBlack}
            alt=""
            src="/webflow--black.svg"
          />
          <img className={styles.relumeBlack} alt="" src={relumeBlack4} />
          <img
            className={styles.webflowBlack}
            alt=""
            src="/webflow--black.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default BreakpointDesktop1;
