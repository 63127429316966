import BreakpointMobile10 from "./BreakpointMobile10";
import BreakpointMobile9 from "./BreakpointMobile9";
import BreakpointMobile8 from "./BreakpointMobile8";
import BreakpointMobile7 from "./BreakpointMobile7";
import BreakpointMobile6 from "./BreakpointMobile6";
import BreakpointMobile5 from "./BreakpointMobile5";
import BreakpointMobile4 from "./BreakpointMobile4";
import BreakpointMobile3 from "./BreakpointMobile3";
import BreakpointMobile2 from "./BreakpointMobile2";
import BreakpointMobile1 from "./BreakpointMobile1";
import BreakpointMobile from "./BreakpointMobile";
import styles from "./SchoolifyContainer1.module.css";

const SchoolifyContainer1 = () => {
  return (
    <div className={styles.home}>
      <BreakpointMobile10
        logo="/logo2.svg"
        iconX="/icon--x1.svg"
        linkOne="About Us"
        linkTwo="Services"
        linkThree="Contact"
        linkFour="More"
        chevronDown="/chevron-down2.svg"
        pageGroupOne="Popular Pages"
        pageOne="Home"
        loremIpsumDolorSitAmetCon="Learn More"
        pageTwo="Features"
        loremIpsumDolorSitAmetCon1="Discover More"
        pageThree="Benefits"
        loremIpsumDolorSitAmetCon2="Explore More"
        pageFour="Demo"
        loremIpsumDolorSitAmetCon3="Try It Now"
        pageGroupTwo="Resources"
        pageFive="Blog"
        loremIpsumDolorSitAmetCon4="Read Our Articles"
        pageSix="FAQ"
        loremIpsumDolorSitAmetCon5="Get Answers Here"
        pageSeven="Support"
        loremIpsumDolorSitAmetCon6="Contact Our Team"
        pageEight="Pricing"
        loremIpsumDolorSitAmetCon7="View Our Plans"
        pageGroupThree="Latest Articles"
        pageNine="How to"
        loremIpsumDolorSitAmetCon8="Learn Something New"
        pageTen="Tips"
        loremIpsumDolorSitAmetCon9="Improve Your Skills"
        pageEleven="Trends"
        loremIpsumDolorSitAmetCon10="Stay Up to Date"
        pageTwelve="Case Studies"
        loremIpsumDolorSitAmetCon11="See Our Successes"
        articleTitle="Title Here"
        readMore="Read More"
        seeAllArticles="View All Articles"
        chevronDown1="/chevron-down3.svg"
        showRow={false}
        breakpointMobileFlexShrink="unset"
      />
      <BreakpointMobile9
        placeholderImage="/placeholder-image9@2x.png"
        mediumLengthHeroHeadlineG="Streamline Your School Operations with Schoolify"
        loremIpsumDolorSitAmetCon="Manage your school efficiently with our all-in-one school management system. Simplify administrative tasks, improve communication, and enhance student learning."
        button="Learn More"
        button1="Sign Up"
        breakpointMobileWidth="375px"
        breakpointMobileHeight="812px"
        contentFlexDirection="column"
        contentPadding="var(--padding-29xl) var(--padding-xl)"
        contentGap="20px"
        columnAlignSelf="stretch"
        columnFlex="unset"
        mediumLengthHeroFontSize="40px"
        columnAlignSelf1="stretch"
        columnGap="8px"
        columnFlex1="unset"
      />
      <BreakpointMobile8
        heading="Simplify School Management with Schoolify's Powerful Features"
        text="Discover how Schoolify can streamline student management, track attendance, and generate report cards with ease."
        subheadingOne="Student Management"
        text1="Effortlessly organize student information, track progress, and communicate with parents."
        subheadingTwo="Attendance Tracking"
        text2="Keep accurate records of student attendance and easily generate attendance reports."
        placeholderImage="/placeholder-image10@2x.png"
        breakpointMobileWidth="375px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-45xl) var(--padding-xl)"
        containerFlexDirection="column"
        containerAlignItems="flex-start"
        containerGap="48px"
        contentAlignSelf="stretch"
        contentGap="24px"
        contentFlex="unset"
        sectionTitleGap="20px"
        headingFontSize="32px"
        listFlexDirection="column"
        listItemAlignSelf="stretch"
        listItemGap="12px"
        listItemFlex="unset"
        listItemAlignSelf1="stretch"
        listItemGap1="12px"
        listItemFlex1="unset"
        placeholderImageIconAlignSelf="stretch"
        placeholderImageIconHeight="348px"
        placeholderImageIconFlexShrink="0"
        placeholderImageIconFlex="unset"
      />
      <BreakpointMobile7
        heading="Effortlessly manage student enrollment, course scheduling, and fee management"
        placeholderImage="/placeholder-image11@2x.png"
        heading1="Streamline your school operations with Schoolify's powerful features"
        text="Schoolify offers a comprehensive solution for managing student enrollment, course scheduling, and fee management. With our intuitive platform, you can easily handle all aspects of your school's administrative tasks."
        button="Learn More"
        placeholderImage1="/placeholder-image12@2x.png"
        heading2="Enhance communication and collaboration with Schoolify's integrated messaging system"
        text1="Our messaging system allows seamless communication between students, parents, and teachers, fostering a collaborative learning environment."
        button1="Learn More"
        iconChevronRight="/icon--relume.svg"
        placeholderImage2="/placeholder-image13@2x.png"
        heading3="Efficiently manage school resources and facilities with Schoolify's advanced tools"
        text2="From managing classrooms and equipment to scheduling facilities, Schoolify provides the tools you need to optimize resource allocation."
        button2="Learn More"
        iconChevronRight1="/icon--relume.svg"
        breakpointMobileWidth="375px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-45xl) var(--padding-xl)"
        breakpointMobileGap="48px"
        headingAlignSelf="stretch"
        headingFontSize="32px"
        headingWidth="unset"
        rowFlexDirection="column"
        rowAlignItems="center"
        rowJustifyContent="flex-start"
        columnAlignSelf="stretch"
        columnGap="24px"
        columnFlex="unset"
        placeholderImageIconHeight="198px"
        contentGap="16px"
        contentGap1="12px"
        headingFontSize1="20px"
        columnAlignSelf1="stretch"
        columnGap1="24px"
        columnFlex1="unset"
        placeholderImageIconHeight1="198px"
        contentGap2="16px"
        contentGap3="12px"
        headingFontSize2="20px"
        columnAlignSelf2="stretch"
        columnGap2="24px"
        columnFlex2="unset"
        placeholderImageIconHeight2="198px"
        contentGap4="16px"
        contentGap5="12px"
        headingFontSize3="20px"
      />
      <BreakpointMobile8
        heading="Unlock the Potential of Your School with Schoolify's Powerful Features"
        text="Schoolify offers improved efficiency, better communication, and data-driven decision-making for your school. Experience the benefits today!"
        subheadingOne="Improved Efficiency"
        text1="Streamline administrative tasks, automate processes, and save time with Schoolify's intuitive interface."
        subheadingTwo="Better Communication"
        text2="Enhance collaboration, facilitate parent-teacher communication, and keep everyone informed in real-time."
        placeholderImage="/placeholder-image14@2x.png"
        breakpointMobileWidth="375px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-45xl) var(--padding-xl)"
        containerFlexDirection="column"
        containerAlignItems="flex-start"
        containerGap="48px"
        contentAlignSelf="stretch"
        contentGap="24px"
        contentFlex="unset"
        sectionTitleGap="20px"
        headingFontSize="32px"
        listFlexDirection="column"
        listItemAlignSelf="stretch"
        listItemGap="12px"
        listItemFlex="unset"
        listItemAlignSelf1="stretch"
        listItemGap1="12px"
        listItemFlex1="unset"
        placeholderImageIconAlignSelf="stretch"
        placeholderImageIconHeight="348px"
        placeholderImageIconFlexShrink="0"
        placeholderImageIconFlex="unset"
      />
      <BreakpointMobile6
        subheading="Simplify"
        heading="Pricing Options"
        text="Choose the plan that suits your needs"
        heading1="Basic Plan"
        price="$19/mo"
        text1="User-friendly interface"
        text2="Attendance tracking"
        text3="Gradebook management"
        heading2="Business Plan"
        price2="$29/mo"
        text4="Advanced reporting features"
        text5="Parent communication tools"
        text6="Customizable grade scales"
        text7="Online assignment submission"
        text8="Integrated messaging system"
        breakpointMobileWidth="375px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-45xl) var(--padding-xl)"
        contentGap="32px"
        sectionTitleWidth="335px"
        sectionTitleGap="12px"
        contentGap1="20px"
        headingFontSize="36px"
        textAlignSelf="stretch"
        contentFlexDirection="column"
        contentAlignItems="center"
        columnJustifyContent="flex-start"
        columnPadding="var(--padding-13xl) var(--padding-5xl)"
        columnGap="32px"
        columnFlex="unset"
        contentGap2="24px"
        priceFontSize="40px"
        priceFontSize1="24px"
        priceLineHeight="140%"
        columnAlignSelf="stretch"
        columnPadding1="var(--padding-13xl) var(--padding-5xl)"
        columnFlex1="unset"
        priceGap="24px"
        containerAlignSelf="unset"
        priceFontSize2="40px"
        priceFontSize3="24px"
        priceLineHeight1="140%"
      />
      <BreakpointMobile5
        heading="Transforming Education with Schoolify"
        text="Discover how Schoolify simplifies school management and enhances student learning. Sign up for a demo today!"
        button="Sign Up"
        button1="Request Demo"
        breakpointMobileWidth="375px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-45xl) var(--padding-xl)"
        containerFlexDirection="column"
        containerGap="20px"
        headingAlignSelf="stretch"
        headingFontSize="36px"
        headingFlex="unset"
        contentAlignSelf="stretch"
        contentGap="8px"
        contentFlex="unset"
      />
      <BreakpointMobile4
        subheading="Simplify"
        heading="Contact Schoolify"
        text="Have questions or need support? Get in touch with us."
        iconEnvelope="/icon--envelope1.svg"
        text1="Send us an email"
        link="hello@schoolify.com"
        iconPhone="/icon--phone.svg"
        text2="Call us for assistance"
        link1="+1 (555) 123-4567"
        iconMap="/icon--map1.svg"
        text3="123 Schoolify St, Sydney NSW 2000 AU"
        placeholderImage="/placeholder-image15@2x.png"
        breakpointMobileWidth="375px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-45xl) var(--padding-xl)"
        breakpointMobileGap="48px"
        sectionTitleAlignSelf="stretch"
        sectionTitleGap="12px"
        sectionTitleWidth="unset"
        subheadingAlignSelf="stretch"
        subheadingTextAlign="left"
        contentGap="20px"
        headingFontSize="36px"
        contentFlexDirection="column"
        contentGap1="48px"
        rowWidth="unset"
        contentGap2="12px"
        contentGap3="12px"
        contentGap4="12px"
        placeholderImageIconAlignSelf="stretch"
        placeholderImageIconMaxWidth="100%"
        placeholderImageIconOverflow="hidden"
        placeholderImageIconHeight="400px"
        placeholderImageIconFlexShrink="0"
        placeholderImageIconWidth="unset"
      />
      <BreakpointMobile3
        subheading="Simplify"
        heading="Get in Touch"
        text="Have a question or want to request a demo?"
        breakpointMobileWidth="375px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-45xl) var(--padding-xl)"
        breakpointMobileGap="32px"
        sectionTitleAlignSelf="stretch"
        sectionTitleGap="12px"
        sectionTitleWidth="unset"
        subheadingAlignSelf="stretch"
        subheadingTextAlign="left"
        contentGap="20px"
        headingFontSize="36px"
        formAlignSelf="stretch"
        formWidth="unset"
      />
      <BreakpointMobile2
        fAQs="Frequently Asked Questions"
        text="Find answers to common questions about Schoolify, its features, and implementation process."
        question="What is Schoolify?"
        icon="/icon.svg"
        question1="How does it work?"
        icon1="/icon.svg"
        question2="Is Schoolify customizable?"
        icon2="/icon.svg"
        question3="How can I implement Schoolify?"
        icon3="/icon.svg"
        question4="What support is available?"
        icon4="/icon.svg"
        breakpointMobileWidth="375px"
        breakpointMobileFlexShrink="unset"
        breakpointMobilePadding="var(--padding-45xl) var(--padding-xl)"
        containerFlexDirection="column"
        containerGap="48px"
        sectionTitleAlignSelf="stretch"
        sectionTitleGap="24px"
        sectionTitleWidth="unset"
        contentGap="20px"
        fAQsFontSize="36px"
        accordionAlignSelf="stretch"
        accordionFlex="unset"
        titlePadding="var(--padding-base) 0px"
        titlePadding1="var(--padding-base) 0px"
        titlePadding2="var(--padding-base) 0px"
        titlePadding3="var(--padding-base) 0px"
        titlePadding4="var(--padding-base) 0px"
      />
      <BreakpointMobile1
        heading="Trusted by leading schools and institutions worldwide"
        relumeBlack="/relume--black1.svg"
        relumeBlack1="/relume--black1.svg"
        relumeBlack2="/relume--black2.svg"
        relumeBlack3="/relume--black2.svg"
        relumeBlack4="/relume--black3.svg"
        breakpointMobileFlexShrink="unset"
      />
      <BreakpointMobile
        logo="/logo3.svg"
        columnOne="About Us"
        linkOne="Features"
        linkTwo="Pricing"
        linkThree="Contact Us"
        linkFour="Support"
        linkFive="FAQ"
        columnTwo="Terms"
        linkSix="Privacy"
        linkSeven="Blog"
        linkEight="Help Center"
        linkNine="Resources"
        linkTen="Partners"
        columnThree="Career"
        linkEleven="Press"
        linkTwelve="Events"
        linkThirteen="Terms of Use"
        linkFourteen="Privacy Policy"
        linkFifteen="Cookie Policy"
        joinOurNewsletterToStayUp="Join our mailing list to receive updates and exclusive offers."
        iconFacebook="/icon--linkedin1.svg"
        iconInstagram="/icon--youtube1.svg"
        privacyPolicy1="© 2023 Schoolify. All rights reserved."
        breakpointMobileFlexShrink="unset"
        privacyPolicyTextDecoration="unset"
        privacyPolicyAlignSelf="stretch"
        privacyPolicyPosition="relative"
        privacyPolicyBackgroundColor="#000"
        privacyPolicyHeight="1px"
        creditsAlignSelf="unset"
        creditsFlexDirection="row"
        creditsGap="12px"
        dividerAlignSelf="unset"
        dividerBackgroundColor="unset"
        dividerHeight="24px"
        dividerWidth="24px"
        dividerOverflow="hidden"
        dividerFlexShrink="0"
        rowAlignSelf="unset"
        rowDisplay="unset"
        rowFlexDirection="unset"
        rowAlignItems="unset"
        rowJustifyContent="unset"
        rowPadding="unset"
        rowBoxSizing="unset"
        rowGap="unset"
        rowPosition="relative"
        rowWidth="24px"
        rowHeight="24px"
        rowOverflow="hidden"
        rowFlexShrink="0"
        socialLinksDisplay="unset"
        socialLinksFlexDirection="unset"
        socialLinksAlignItems="unset"
        socialLinksJustifyContent="unset"
        socialLinksGap="unset"
        socialLinksPosition="relative"
        socialLinksWidth="24px"
        socialLinksHeight="24px"
        socialLinksOverflow="hidden"
        socialLinksFlexShrink="0"
        iconXPosition="unset"
        iconXWidth="unset"
        iconXHeight="unset"
        iconXOverflow="unset"
        iconXFlexShrink="unset"
        iconXDisplay="flex"
        iconXFlexDirection="column"
        iconXAlignItems="flex-start"
        iconXJustifyContent="flex-start"
        iconXGap="32px"
        iconLinkedInPosition="unset"
        iconLinkedInWidth="unset"
        iconLinkedInHeight="unset"
        iconLinkedInOverflow="unset"
        iconLinkedInFlexShrink="unset"
        iconLinkedInDisplay="flex"
        iconLinkedInFlexDirection="column"
        iconLinkedInAlignItems="flex-start"
        iconLinkedInJustifyContent="flex-start"
        iconLinkedInGap="16px"
        iconYoutubeWidth="unset"
        iconYoutubeHeight="unset"
        iconYoutubeOverflow="unset"
        iconYoutubeFlexShrink="unset"
        iconYoutubeFontSize="14px"
        iconYoutubeTextDecoration="underline"
        iconYoutubeLineHeight="150%"
        iconYoutubeFontFamily="Roboto"
        iconYoutubeColor="#000"
        iconYoutubeTextAlign="left"
        iconYoutubeDisplay="inline-block"
        creditsDisplay="inline-block"
        creditsFlexDirection1="unset"
        creditsAlignItems="unset"
        creditsJustifyContent="unset"
        creditsGap1="unset"
        creditsPosition="relative"
        creditsFontSize="14px"
        creditsTextDecoration="underline"
        creditsLineHeight="150%"
        creditsFontFamily="Roboto"
        creditsColor="#000"
        creditsTextAlign="left"
        footerLinksDisplay="inline-block"
        footerLinksFlexDirection="unset"
        footerLinksAlignItems="unset"
        footerLinksJustifyContent="unset"
        footerLinksGap="unset"
        footerLinksPosition="relative"
        footerLinksFontSize="14px"
        footerLinksTextDecoration="underline"
        footerLinksLineHeight="150%"
        footerLinksFontFamily="Roboto"
        footerLinksColor="#000"
        footerLinksTextAlign="left"
        privacyPolicyTextDecoration1="unset"
      />
    </div>
  );
};

export default SchoolifyContainer1;
