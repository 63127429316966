import styles from "./StylePrimarySmallTrueDar1.module.css";

const StylePrimarySmallTrueDar1 = () => {
  return (
    <div className={styles.styleprimarySmalltrueDar}>
      <div className={styles.button}>Button</div>
    </div>
  );
};

export default StylePrimarySmallTrueDar1;
