import styles from "./StyleLinkSmallFalseDark.module.css";

const StyleLinkSmallFalseDark = ({ button, iconRelume }) => {
  return (
    <div className={styles.stylelinkSmallfalseDark}>
      <div className={styles.button}>{button}</div>
      <img className={styles.iconRelume} alt="" src={iconRelume} />
    </div>
  );
};

export default StyleLinkSmallFalseDark;
