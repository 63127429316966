import { useMemo } from "react";
import LinkCard from "./LinkCard";
import styles from "./BreakpointDesktop10.module.css";

const BreakpointDesktop10 = ({
  logo,
  linkOne,
  linkTwo,
  linkThree,
  linkFour,
  chevronDown,
  pageGroupOne,
  pageOne,
  loremIpsumDolorSitAmetCon,
  pageTwo,
  loremIpsumDolorSitAmetCon1,
  pageThree,
  loremIpsumDolorSitAmetCon2,
  pageFour,
  loremIpsumDolorSitAmetCon3,
  pageGroupTwo,
  pageFive,
  loremIpsumDolorSitAmetCon4,
  pageSix,
  loremIpsumDolorSitAmetCon5,
  pageSeven,
  loremIpsumDolorSitAmetCon6,
  pageEight,
  loremIpsumDolorSitAmetCon7,
  pageGroupThree,
  pageNine,
  loremIpsumDolorSitAmetCon8,
  pageTen,
  loremIpsumDolorSitAmetCon9,
  pageEleven,
  loremIpsumDolorSitAmetCon10,
  pageTwelve,
  loremIpsumDolorSitAmetCon11,
  articleTitle,
  readMore,
  seeAllArticles,
  chevronDown1,
  showMegaMenu,
  breakpointDesktopFlexShrink,
}) => {
  const breakpointDesktop2Style = useMemo(() => {
    return {
      flexShrink: breakpointDesktopFlexShrink,
    };
  }, [breakpointDesktopFlexShrink]);

  return (
    <div className={styles.breakpointDesktop} style={breakpointDesktop2Style}>
      <LinkCard
        linkText="/logo5.svg"
        linkText2="Link One"
        linkDescription="Link Two"
        linkIcon="Link Three"
        linkButtonText="Link Four"
        imageDimensions="/chevron-down6.svg"
      />
      {showMegaMenu && (
        <div className={styles.megaMenu}>
          <div className={styles.menu}>
            <div className={styles.menuList}>
              <div className={styles.pageGroupOne}>{pageGroupOne}</div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageOne}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon}
                  </div>
                </div>
              </div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageTwo}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon1}
                  </div>
                </div>
              </div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageThree}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon2}
                  </div>
                </div>
              </div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageFour}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon3}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.menuList}>
              <div className={styles.pageGroupOne}>{pageGroupTwo}</div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageFive}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon4}
                  </div>
                </div>
              </div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageSix}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon5}
                  </div>
                </div>
              </div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageSeven}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon6}
                  </div>
                </div>
              </div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageEight}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon7}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.menuList}>
              <div className={styles.pageGroupOne}>{pageGroupThree}</div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageNine}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon8}
                  </div>
                </div>
              </div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageTen}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon9}
                  </div>
                </div>
              </div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageEleven}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon10}
                  </div>
                </div>
              </div>
              <div className={styles.menuItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{pageTwelve}</div>
                  <div className={styles.loremIpsumDolor}>
                    {loremIpsumDolorSitAmetCon11}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.blog}>
            <div className={styles.pageGroupOne}>Featured from Blog</div>
            <div className={styles.blogItem}>
              <img
                className={styles.placeholderImageIcon}
                alt=""
                src="/placeholder-image@2x.png"
              />
              <div className={styles.content12}>
                <div className={styles.content13}>
                  <div className={styles.pageOne}>{articleTitle}</div>
                  <div className={styles.loremIpsumDolor}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </div>
                </div>
                <div className={styles.readMore}>{readMore}</div>
              </div>
            </div>
            <div className={styles.button}>
              <div className={styles.seeAllArticles}>{seeAllArticles}</div>
              <img
                className={styles.chevronDownIcon}
                alt=""
                src={chevronDown1}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BreakpointDesktop10;
