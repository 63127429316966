import { useMemo } from "react";
import TextInput3 from "./TextInput3";
import StyleSecondarySmallFalse from "./StyleSecondarySmallFalse";
import styles from "./BreakpointDesktop.module.css";

const BreakpointDesktop = ({
  logo,
  columnOne,
  linkOne,
  linkTwo,
  linkThree,
  linkFour,
  linkFive,
  columnTwo,
  linkSix,
  linkSeven,
  linkEight,
  linkNine,
  linkTen,
  columnThree,
  linkEleven,
  linkTwelve,
  linkThirteen,
  linkFourteen,
  linkFifteen,
  joinOurNewsletterToStayUp,
  privacyPolicy,
  relumeAllRightsReserved,
  privacyPolicy1,
  termsOfService,
  cookiesSettings,
  iconFacebook,
  iconInstagram,
  breakpointDesktopFlexShrink,
  privacyPolicyTextDecoration,
  privacyPolicyAlignSelf,
  privacyPolicyPosition,
  privacyPolicyBackgroundColor,
  privacyPolicyHeight,
  creditsAlignSelf,
  creditsFlexDirection,
  creditsAlignItems,
  creditsGap,
  dividerAlignSelf,
  dividerBackgroundColor,
  dividerHeight,
  dividerFontSize,
  dividerLineHeight,
  dividerFontFamily,
  dividerColor,
  dividerTextAlign,
  dividerDisplay,
  rowAlignSelf,
  rowJustifyContent,
  rowGap,
  creditsDisplay,
  creditsFlexDirection1,
  creditsAlignItems1,
  creditsJustifyContent,
  creditsGap1,
  creditsPosition,
  creditsFontSize,
  creditsTextDecoration,
  creditsLineHeight,
  creditsFontFamily,
  creditsColor,
  creditsTextAlign,
  relumeAllRightsTextDecoration,
  footerLinksDisplay,
  footerLinksFlexDirection,
  footerLinksAlignItems,
  footerLinksJustifyContent,
  footerLinksGap,
  footerLinksPosition,
  footerLinksFontSize,
  footerLinksTextDecoration,
  footerLinksLineHeight,
  footerLinksFontFamily,
  footerLinksColor,
  footerLinksTextAlign,
  privacyPolicyPosition1,
  privacyPolicyFontSize,
  privacyPolicyTextDecoration1,
  privacyPolicyLineHeight,
  privacyPolicyFontFamily,
  privacyPolicyColor,
  privacyPolicyTextAlign,
  privacyPolicyDisplay,
  privacyPolicyFlexDirection,
  privacyPolicyAlignItems,
  privacyPolicyJustifyContent,
  privacyPolicyGap,
  termsOfServiceFontSize,
  termsOfServiceTextDecoration,
  termsOfServiceLineHeight,
  termsOfServiceFontFamily,
  termsOfServiceColor,
  termsOfServiceTextAlign,
  termsOfServiceDisplay,
  termsOfServiceWidth,
  termsOfServiceHeight,
  termsOfServiceOverflow,
  termsOfServiceFlexShrink,
  cookiesSettingsFontSize,
  cookiesSettingsTextDecoration,
  cookiesSettingsLineHeight,
  cookiesSettingsFontFamily,
  cookiesSettingsColor,
  cookiesSettingsTextAlign,
  cookiesSettingsDisplay,
  cookiesSettingsWidth,
  cookiesSettingsHeight,
  cookiesSettingsOverflow,
  cookiesSettingsFlexShrink,
  socialLinksDisplay,
  socialLinksFlexDirection,
  socialLinksAlignItems,
  socialLinksJustifyContent,
  socialLinksGap,
  socialLinksPosition,
  socialLinksWidth,
  socialLinksHeight,
  socialLinksOverflow,
  socialLinksFlexShrink,
}) => {
  const breakpointDesktopStyle = useMemo(() => {
    return {
      flexShrink: breakpointDesktopFlexShrink,
    };
  }, [breakpointDesktopFlexShrink]);

  const privacyPolicyStyle = useMemo(() => {
    return {
      textDecoration: privacyPolicyTextDecoration,
      alignSelf: privacyPolicyAlignSelf,
      position: privacyPolicyPosition,
      backgroundColor: privacyPolicyBackgroundColor,
      height: privacyPolicyHeight,
    };
  }, [
    privacyPolicyTextDecoration,
    privacyPolicyAlignSelf,
    privacyPolicyPosition,
    privacyPolicyBackgroundColor,
    privacyPolicyHeight,
  ]);

  const creditsStyle = useMemo(() => {
    return {
      alignSelf: creditsAlignSelf,
      flexDirection: creditsFlexDirection,
      alignItems: creditsAlignItems,
      gap: creditsGap,
    };
  }, [creditsAlignSelf, creditsFlexDirection, creditsAlignItems, creditsGap]);

  const dividerStyle = useMemo(() => {
    return {
      alignSelf: dividerAlignSelf,
      backgroundColor: dividerBackgroundColor,
      height: dividerHeight,
      fontSize: dividerFontSize,
      lineHeight: dividerLineHeight,
      fontFamily: dividerFontFamily,
      color: dividerColor,
      textAlign: dividerTextAlign,
      display: dividerDisplay,
    };
  }, [
    dividerAlignSelf,
    dividerBackgroundColor,
    dividerHeight,
    dividerFontSize,
    dividerLineHeight,
    dividerFontFamily,
    dividerColor,
    dividerTextAlign,
    dividerDisplay,
  ]);

  const rowStyle = useMemo(() => {
    return {
      alignSelf: rowAlignSelf,
      justifyContent: rowJustifyContent,
      gap: rowGap,
    };
  }, [rowAlignSelf, rowJustifyContent, rowGap]);

  const credits1Style = useMemo(() => {
    return {
      display: creditsDisplay,
      flexDirection: creditsFlexDirection1,
      alignItems: creditsAlignItems1,
      justifyContent: creditsJustifyContent,
      gap: creditsGap1,
      position: creditsPosition,
      fontSize: creditsFontSize,
      textDecoration: creditsTextDecoration,
      lineHeight: creditsLineHeight,
      fontFamily: creditsFontFamily,
      color: creditsColor,
      textAlign: creditsTextAlign,
    };
  }, [
    creditsDisplay,
    creditsFlexDirection1,
    creditsAlignItems1,
    creditsJustifyContent,
    creditsGap1,
    creditsPosition,
    creditsFontSize,
    creditsTextDecoration,
    creditsLineHeight,
    creditsFontFamily,
    creditsColor,
    creditsTextAlign,
  ]);

  const relumeAllRightsStyle = useMemo(() => {
    return {
      textDecoration: relumeAllRightsTextDecoration,
    };
  }, [relumeAllRightsTextDecoration]);

  const footerLinksStyle = useMemo(() => {
    return {
      display: footerLinksDisplay,
      flexDirection: footerLinksFlexDirection,
      alignItems: footerLinksAlignItems,
      justifyContent: footerLinksJustifyContent,
      gap: footerLinksGap,
      position: footerLinksPosition,
      fontSize: footerLinksFontSize,
      textDecoration: footerLinksTextDecoration,
      lineHeight: footerLinksLineHeight,
      fontFamily: footerLinksFontFamily,
      color: footerLinksColor,
      textAlign: footerLinksTextAlign,
    };
  }, [
    footerLinksDisplay,
    footerLinksFlexDirection,
    footerLinksAlignItems,
    footerLinksJustifyContent,
    footerLinksGap,
    footerLinksPosition,
    footerLinksFontSize,
    footerLinksTextDecoration,
    footerLinksLineHeight,
    footerLinksFontFamily,
    footerLinksColor,
    footerLinksTextAlign,
  ]);

  const privacyPolicy1Style = useMemo(() => {
    return {
      position: privacyPolicyPosition1,
      fontSize: privacyPolicyFontSize,
      textDecoration: privacyPolicyTextDecoration1,
      lineHeight: privacyPolicyLineHeight,
      fontFamily: privacyPolicyFontFamily,
      color: privacyPolicyColor,
      textAlign: privacyPolicyTextAlign,
      display: privacyPolicyDisplay,
      flexDirection: privacyPolicyFlexDirection,
      alignItems: privacyPolicyAlignItems,
      justifyContent: privacyPolicyJustifyContent,
      gap: privacyPolicyGap,
    };
  }, [
    privacyPolicyPosition1,
    privacyPolicyFontSize,
    privacyPolicyTextDecoration1,
    privacyPolicyLineHeight,
    privacyPolicyFontFamily,
    privacyPolicyColor,
    privacyPolicyTextAlign,
    privacyPolicyDisplay,
    privacyPolicyFlexDirection,
    privacyPolicyAlignItems,
    privacyPolicyJustifyContent,
    privacyPolicyGap,
  ]);

  const termsOfServiceStyle = useMemo(() => {
    return {
      fontSize: termsOfServiceFontSize,
      textDecoration: termsOfServiceTextDecoration,
      lineHeight: termsOfServiceLineHeight,
      fontFamily: termsOfServiceFontFamily,
      color: termsOfServiceColor,
      textAlign: termsOfServiceTextAlign,
      display: termsOfServiceDisplay,
      width: termsOfServiceWidth,
      height: termsOfServiceHeight,
      overflow: termsOfServiceOverflow,
      flexShrink: termsOfServiceFlexShrink,
    };
  }, [
    termsOfServiceFontSize,
    termsOfServiceTextDecoration,
    termsOfServiceLineHeight,
    termsOfServiceFontFamily,
    termsOfServiceColor,
    termsOfServiceTextAlign,
    termsOfServiceDisplay,
    termsOfServiceWidth,
    termsOfServiceHeight,
    termsOfServiceOverflow,
    termsOfServiceFlexShrink,
  ]);

  const cookiesSettingsStyle = useMemo(() => {
    return {
      fontSize: cookiesSettingsFontSize,
      textDecoration: cookiesSettingsTextDecoration,
      lineHeight: cookiesSettingsLineHeight,
      fontFamily: cookiesSettingsFontFamily,
      color: cookiesSettingsColor,
      textAlign: cookiesSettingsTextAlign,
      display: cookiesSettingsDisplay,
      width: cookiesSettingsWidth,
      height: cookiesSettingsHeight,
      overflow: cookiesSettingsOverflow,
      flexShrink: cookiesSettingsFlexShrink,
    };
  }, [
    cookiesSettingsFontSize,
    cookiesSettingsTextDecoration,
    cookiesSettingsLineHeight,
    cookiesSettingsFontFamily,
    cookiesSettingsColor,
    cookiesSettingsTextAlign,
    cookiesSettingsDisplay,
    cookiesSettingsWidth,
    cookiesSettingsHeight,
    cookiesSettingsOverflow,
    cookiesSettingsFlexShrink,
  ]);

  const socialLinksStyle = useMemo(() => {
    return {
      display: socialLinksDisplay,
      flexDirection: socialLinksFlexDirection,
      alignItems: socialLinksAlignItems,
      justifyContent: socialLinksJustifyContent,
      gap: socialLinksGap,
      position: socialLinksPosition,
      width: socialLinksWidth,
      height: socialLinksHeight,
      overflow: socialLinksOverflow,
      flexShrink: socialLinksFlexShrink,
    };
  }, [
    socialLinksDisplay,
    socialLinksFlexDirection,
    socialLinksAlignItems,
    socialLinksJustifyContent,
    socialLinksGap,
    socialLinksPosition,
    socialLinksWidth,
    socialLinksHeight,
    socialLinksOverflow,
    socialLinksFlexShrink,
  ]);

  return (
    <div className={styles.breakpointDesktop} style={breakpointDesktopStyle}>
      <div className={styles.content}>
        <div className={styles.links}>
          <div className={styles.column}>
            <img className={styles.logoIcon} alt="" src={logo} />
          </div>
          <div className={styles.column1}>
            <div className={styles.columnOne}>{columnOne}</div>
            <div className={styles.footerLinks}>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkOne}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkTwo}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkThree}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkFour}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkFive}</div>
              </div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.columnOne}>{columnTwo}</div>
            <div className={styles.footerLinks}>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkSix}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkSeven}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkEight}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkNine}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkTen}</div>
              </div>
            </div>
          </div>
          <div className={styles.column1}>
            <div className={styles.columnOne}>{columnThree}</div>
            <div className={styles.footerLinks}>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkEleven}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkTwelve}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkThirteen}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkFourteen}</div>
              </div>
              <div className={styles.link}>
                <div className={styles.linkOne}>{linkFifteen}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.newslatter}>
          <div className={styles.subscribeParent}>
            <div className={styles.columnOne}>Subscribe</div>
            <div className={styles.joinOurNewsletter}>
              {joinOurNewsletterToStayUp}
            </div>
          </div>
          <div className={styles.actions}>
            <div className={styles.form}>
              <TextInput3 />
              <StyleSecondarySmallFalse
                styleSecondarySmallFalseWidth="121px"
                styleSecondarySmallFalseAlignSelf="unset"
              />
            </div>
            <div className={styles.joinOurNewsletter}>
              {`By subscribing you agree to with our `}
              <span className={styles.privacyPolicy} style={privacyPolicyStyle}>
                {privacyPolicy}
              </span>{" "}
              and provide consent to receive updates from our company.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.credits} style={creditsStyle}>
        <div className={styles.divider} style={dividerStyle} />
        <div className={styles.row} style={rowStyle}>
          <div className={styles.credits1} style={credits1Style}>
            <div
              className={styles.relumeAllRights}
              style={relumeAllRightsStyle}
            >
              {relumeAllRightsReserved}
            </div>
            <div className={styles.footerLinks3} style={footerLinksStyle}>
              <div
                className={styles.termsOfService}
                style={privacyPolicy1Style}
              >
                {privacyPolicy1}
              </div>
              <div
                className={styles.termsOfService}
                style={termsOfServiceStyle}
              >
                {termsOfService}
              </div>
              <div
                className={styles.termsOfService}
                style={cookiesSettingsStyle}
              >
                {cookiesSettings}
              </div>
            </div>
          </div>
          <div className={styles.socialLinks} style={socialLinksStyle}>
            <img className={styles.iconFacebook} alt="" src={iconFacebook} />
            <img className={styles.iconFacebook} alt="" src={iconInstagram} />
            <img className={styles.iconFacebook} alt="" src="/icon--x2.svg" />
            <img
              className={styles.iconFacebook}
              alt=""
              src="/icon--linkedin1.svg"
            />
            <img
              className={styles.iconFacebook}
              alt=""
              src="/icon--youtube2.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakpointDesktop;
