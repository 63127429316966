import { useMemo } from "react";
import StylePrimarySmallFalseDa from "./StylePrimarySmallFalseDa";
import StyleSecondarySmallFalse1 from "./StyleSecondarySmallFalse1";
import styles from "./BreakpointMobile9.module.css";

const BreakpointMobile9 = ({
  placeholderImage,
  mediumLengthHeroHeadlineG,
  loremIpsumDolorSitAmetCon,
  button,
  button1,
  breakpointMobileWidth,
  breakpointMobileHeight,
  contentFlexDirection,
  contentPadding,
  contentGap,
  columnAlignSelf,
  columnFlex,
  mediumLengthHeroFontSize,
  columnAlignSelf1,
  columnGap,
  columnFlex1,
}) => {
  const breakpointMobile9Style = useMemo(() => {
    return {
      width: breakpointMobileWidth,
      height: breakpointMobileHeight,
    };
  }, [breakpointMobileWidth, breakpointMobileHeight]);

  const content19Style = useMemo(() => {
    return {
      flexDirection: contentFlexDirection,
      padding: contentPadding,
      gap: contentGap,
    };
  }, [contentFlexDirection, contentPadding, contentGap]);

  const column5Style = useMemo(() => {
    return {
      alignSelf: columnAlignSelf,
      flex: columnFlex,
    };
  }, [columnAlignSelf, columnFlex]);

  const mediumLengthHeroStyle = useMemo(() => {
    return {
      fontSize: mediumLengthHeroFontSize,
    };
  }, [mediumLengthHeroFontSize]);

  const column6Style = useMemo(() => {
    return {
      alignSelf: columnAlignSelf1,
      gap: columnGap,
      flex: columnFlex1,
    };
  }, [columnAlignSelf1, columnGap, columnFlex1]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobile9Style}>
      <img
        className={styles.placeholderImageIcon}
        alt=""
        src={placeholderImage}
      />
      <div className={styles.content} style={content19Style}>
        <div className={styles.column} style={column5Style}>
          <b className={styles.mediumLengthHero} style={mediumLengthHeroStyle}>
            {mediumLengthHeroHeadlineG}
          </b>
        </div>
        <div className={styles.column1} style={column6Style}>
          <div className={styles.loremIpsumDolor}>
            {loremIpsumDolorSitAmetCon}
          </div>
          <div className={styles.actions}>
            <StylePrimarySmallFalseDa
              button="Button"
              stylePrimarySmallFalseDaAlignSelf="unset"
              stylePrimarySmallFalseDaWidth="unset"
            />
            <StyleSecondarySmallFalse1
              button="Button"
              styleSecondarySmallFalseWidth="unset"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakpointMobile9;
