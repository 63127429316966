import { useMemo } from "react";
import StyleLinkSmallFalseDark1 from "./StyleLinkSmallFalseDark1";
import StyleLinkSmallFalseDark from "./StyleLinkSmallFalseDark";
import StyleLinkSmallFalseDark2 from "./StyleLinkSmallFalseDark2";
import styles from "./BreakpointMobile7.module.css";

const BreakpointMobile7 = ({
  heading,
  placeholderImage,
  heading1,
  text,
  button,
  placeholderImage1,
  heading2,
  text1,
  button1,
  iconChevronRight,
  placeholderImage2,
  heading3,
  text2,
  button2,
  iconChevronRight1,
  breakpointMobileWidth,
  breakpointMobileFlexShrink,
  breakpointMobilePadding,
  breakpointMobileGap,
  headingAlignSelf,
  headingFontSize,
  headingWidth,
  rowFlexDirection,
  rowAlignItems,
  rowJustifyContent,
  columnAlignSelf,
  columnGap,
  columnFlex,
  placeholderImageIconHeight,
  contentGap,
  contentGap1,
  headingFontSize1,
  columnAlignSelf1,
  columnGap1,
  columnFlex1,
  placeholderImageIconHeight1,
  contentGap2,
  contentGap3,
  headingFontSize2,
  columnAlignSelf2,
  columnGap2,
  columnFlex2,
  placeholderImageIconHeight2,
  contentGap4,
  contentGap5,
  headingFontSize3,
}) => {
  const breakpointMobile7Style = useMemo(() => {
    return {
      width: breakpointMobileWidth,
      flexShrink: breakpointMobileFlexShrink,
      padding: breakpointMobilePadding,
      gap: breakpointMobileGap,
    };
  }, [
    breakpointMobileWidth,
    breakpointMobileFlexShrink,
    breakpointMobilePadding,
    breakpointMobileGap,
  ]);

  const heading4Style = useMemo(() => {
    return {
      alignSelf: headingAlignSelf,
      fontSize: headingFontSize,
      width: headingWidth,
    };
  }, [headingAlignSelf, headingFontSize, headingWidth]);

  const row3Style = useMemo(() => {
    return {
      flexDirection: rowFlexDirection,
      alignItems: rowAlignItems,
      justifyContent: rowJustifyContent,
    };
  }, [rowFlexDirection, rowAlignItems, rowJustifyContent]);

  const column2Style = useMemo(() => {
    return {
      alignSelf: columnAlignSelf,
      gap: columnGap,
      flex: columnFlex,
    };
  }, [columnAlignSelf, columnGap, columnFlex]);

  const placeholderImageIcon1Style = useMemo(() => {
    return {
      height: placeholderImageIconHeight,
    };
  }, [placeholderImageIconHeight]);

  const content12Style = useMemo(() => {
    return {
      gap: contentGap,
    };
  }, [contentGap]);

  const content13Style = useMemo(() => {
    return {
      gap: contentGap1,
    };
  }, [contentGap1]);

  const heading5Style = useMemo(() => {
    return {
      fontSize: headingFontSize1,
    };
  }, [headingFontSize1]);

  const column3Style = useMemo(() => {
    return {
      alignSelf: columnAlignSelf1,
      gap: columnGap1,
      flex: columnFlex1,
    };
  }, [columnAlignSelf1, columnGap1, columnFlex1]);

  const placeholderImageIcon2Style = useMemo(() => {
    return {
      height: placeholderImageIconHeight1,
    };
  }, [placeholderImageIconHeight1]);

  const content14Style = useMemo(() => {
    return {
      gap: contentGap2,
    };
  }, [contentGap2]);

  const content15Style = useMemo(() => {
    return {
      gap: contentGap3,
    };
  }, [contentGap3]);

  const heading6Style = useMemo(() => {
    return {
      fontSize: headingFontSize2,
    };
  }, [headingFontSize2]);

  const column4Style = useMemo(() => {
    return {
      alignSelf: columnAlignSelf2,
      gap: columnGap2,
      flex: columnFlex2,
    };
  }, [columnAlignSelf2, columnGap2, columnFlex2]);

  const placeholderImageIcon3Style = useMemo(() => {
    return {
      height: placeholderImageIconHeight2,
    };
  }, [placeholderImageIconHeight2]);

  const content16Style = useMemo(() => {
    return {
      gap: contentGap4,
    };
  }, [contentGap4]);

  const content17Style = useMemo(() => {
    return {
      gap: contentGap5,
    };
  }, [contentGap5]);

  const heading7Style = useMemo(() => {
    return {
      fontSize: headingFontSize3,
    };
  }, [headingFontSize3]);

  return (
    <div className={styles.breakpointMobile} style={breakpointMobile7Style}>
      <b className={styles.heading} style={heading4Style}>
        {heading}
      </b>
      <div className={styles.row} style={row3Style}>
        <div className={styles.column} style={column2Style}>
          <img
            className={styles.placeholderImageIcon}
            alt=""
            src={placeholderImage}
            style={placeholderImageIcon1Style}
          />
          <div className={styles.content} style={content12Style}>
            <div className={styles.content1} style={content13Style}>
              <b className={styles.heading1} style={heading5Style}>
                {heading1}
              </b>
              <div className={styles.text}>{text}</div>
            </div>
            <div className={styles.action}>
              <StyleLinkSmallFalseDark1
                button="Button"
                iconRelume="/icon--relume.svg"
              />
            </div>
          </div>
        </div>
        <div className={styles.column} style={column3Style}>
          <img
            className={styles.placeholderImageIcon}
            alt=""
            src={placeholderImage1}
            style={placeholderImageIcon2Style}
          />
          <div className={styles.content} style={content14Style}>
            <div className={styles.content1} style={content15Style}>
              <b className={styles.heading1} style={heading6Style}>
                {heading2}
              </b>
              <div className={styles.text}>{text1}</div>
            </div>
            <div className={styles.action}>
              <StyleLinkSmallFalseDark
                button="Button"
                iconRelume="/icon--relume.svg"
              />
            </div>
          </div>
        </div>
        <div className={styles.column} style={column4Style}>
          <img
            className={styles.placeholderImageIcon}
            alt=""
            src={placeholderImage2}
            style={placeholderImageIcon3Style}
          />
          <div className={styles.content} style={content16Style}>
            <div className={styles.content1} style={content17Style}>
              <b className={styles.heading1} style={heading7Style}>
                {heading3}
              </b>
              <div className={styles.text}>{text2}</div>
            </div>
            <div className={styles.action}>
              <StyleLinkSmallFalseDark2
                button="Button"
                iconRelume="/icon--relume.svg"
                styleLinkSmallFalseDarkWidth="unset"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakpointMobile7;
