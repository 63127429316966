import { useMemo } from "react";
import styles from "./StylePrimarySmallFalseDa.module.css";

const StylePrimarySmallFalseDa = ({
  button,
  stylePrimarySmallFalseDaAlignSelf,
  stylePrimarySmallFalseDaWidth,
}) => {
  const stylePrimarySmallFalseDaStyle = useMemo(() => {
    return {
      alignSelf: stylePrimarySmallFalseDaAlignSelf,
      width: stylePrimarySmallFalseDaWidth,
    };
  }, [stylePrimarySmallFalseDaAlignSelf, stylePrimarySmallFalseDaWidth]);

  return (
    <div
      className={styles.styleprimarySmallfalseDa}
      style={stylePrimarySmallFalseDaStyle}
    >
      <div className={styles.button}>{button}</div>
    </div>
  );
};

export default StylePrimarySmallFalseDa;
