import styles from "./StyleSecondarySmallTrueD1.module.css";

const StyleSecondarySmallTrueD1 = () => {
  return (
    <div className={styles.stylesecondarySmalltrueD}>
      <div className={styles.button}>Button</div>
    </div>
  );
};

export default StyleSecondarySmallTrueD1;
